import { Injectable } from '@angular/core';
import { UserServiceAdapter } from './user.service.adapter';
import { User, USER, UserGetAction, UserInterface, UserViewedEvent } from '@wam/models';
import { RestService } from '@digitaltoolbuilders/ng-api';
import { RestServerResponse } from '@digitaltoolbuilders/iso';

@Injectable({
  providedIn: 'root'
})
export class RestServiceAdapter implements UserServiceAdapter {

  readonly path = `/${USER}`;

  constructor(private rest: RestService) { }

  get(action: UserGetAction): Promise<UserViewedEvent> {
  
    return this.rest.post<RestServerResponse<UserInterface>>(this.path, action)
    .then((response) => {

      return new UserViewedEvent({
        ...response.data,
        payload: new User(response.data.payload),
      });

    });
    
  }

}
