import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscModule } from './disc/disc.module';



@NgModule({
  declarations: [],
  exports: [
    DiscModule,
  ],
  imports: [
    CommonModule,
    DiscModule,
  ]
})
export class WoodModule { }
