<div class="flex flex-col">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let disc">
        <ng-container *ngIf="actions() as a"><ng-container *ngTemplateOutlet="a; context: { disc }"></ng-container></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="disc_id">
      <th mat-header-cell *matHeaderCellDef>Disc Id</th>
      <td mat-cell *matCellDef="let disc">{{disc.disc_id}}</td>
    </ng-container>
    <ng-container matColumnDef="shape">
      <th mat-header-cell *matHeaderCellDef>Shape</th>
      <td mat-cell *matCellDef="let disc">{{disc.shape}}</td>
    </ng-container>
    <ng-container matColumnDef="species">
      <th mat-header-cell *matHeaderCellDef>Species</th>
      <td mat-cell *matCellDef="let disc">{{disc.species}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let disc">{{disc.status}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display()"></tr>
    <tr mat-row *matRowDef="let disc; columns: display()"></tr>

    <tr class="mat-row" *matNoDataRow><td [attr.colspan]="display().length">No Discs</td></tr>
  </table>

</div>
