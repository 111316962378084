import { Injectable } from '@angular/core';
import { DiscServiceAdapter } from './disc.service.adapter';
import { WoodDisc, WoodDiscCreateAction, WoodDiscCreatedEvent, WoodDiscGetAction, WoodDiscInterface, WoodDiscListAction, WoodDiscListedEvent, WoodDiscUpdateAction, WoodDiscUpdatedEvent, WoodDiscViewedEvent } from '@wam/models';
import { WebSocketService } from '@digitaltoolbuilders/ng-api';
import { ModelList } from '@digitaltoolbuilders/iso';

@Injectable({
  providedIn: 'root'
})
export class WebsocketServiceAdapter implements DiscServiceAdapter {

  constructor(private socket: WebSocketService) { }

  create(action: WoodDiscCreateAction): Promise<WoodDiscCreatedEvent> {
    
    return this.socket.request<WoodDiscInterface>(action)
    .then((message) => {

      return new WoodDiscCreatedEvent({
        ...message,
        payload: new WoodDisc(message.payload),
      });

    });

  }

  get(action: WoodDiscGetAction): Promise<WoodDiscViewedEvent> {
      
    return this.socket.request<WoodDiscInterface>(action)
    .then((message) => {

      return new WoodDiscViewedEvent({
        ...message,
        payload: new WoodDisc(message.payload),
      });

    });

  }

  list(action: WoodDiscListAction): Promise<WoodDiscListedEvent> {
     
    return this.socket.request<ModelList<WoodDiscInterface>>(action)
    .then((message) => {

      return new WoodDiscListedEvent({
        ...message,
        payload: new ModelList<WoodDisc>({
          ...message.payload,
          models: message.payload.models.map((item) => new WoodDisc(item)),
        }),
      });

    });

  }

  update(action: WoodDiscUpdateAction): Promise<WoodDiscUpdatedEvent> {
    
    return this.socket.request<WoodDiscInterface>(action)
    .then((message) => {

      return new WoodDiscUpdatedEvent({
        ...message,
        payload: new WoodDisc(message.payload),
      });

    });

  }

}
