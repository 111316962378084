<div class="flex flex-col">

  <div class="flex flex-row justify-between">

    <h1 class="text-lg">Available For Purchase</h1>

    <div class="flex flex-row">

      <mat-spinner *ngIf="loading()" diameter="30"></mat-spinner>

    </div>

  </div>

  <ng-wood-disc-card-list
    [discs]="discs()"></ng-wood-disc-card-list>

</div>
