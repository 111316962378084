import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { LastEvaluatedKey } from '@digitaltoolbuilders/iso';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import { WoodDisc } from '@wam/models';
import { WoodDiscService } from '@wam/ng-models';
import { fetchAuthSession } from '@aws-amplify/auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-view-available',
  standalone: false,
  templateUrl: './view-available.component.html',
  styleUrl: './view-available.component.sass',
})
export class ViewAvailableComponent {

  discs: Signal<Array<WoodDisc>>;

  last: Signal<LastEvaluatedKey | undefined>;

  loading: Signal<boolean>;

  more: Signal<boolean>;

  constructor(
    private logger: LoggerService,
    private service: WoodDiscService,
  ) {

    this.discs = computed(() => {

      return this.service.all().models;

    });

    this.last = computed(() => {

      return this.service.all().last;

    });

    this.loading = computed(() => {

      return this.service.all().loading;

    });

    this.more = computed(() => {

      return (this.service.all().last) ? true : false;

    });

    fetchAuthSession({ forceRefresh: true})
    .then((session) => {

      this.logger.debug({ session }, 'fetchAuthSession');

    });

    this.fetchDiscs();

  }

  fetchDiscs() {

    const last = this.last();

    this.logger.debug({ last }, 'fetchDiscs');

    return this.service.list({
      ExclusiveStartKey: last,
    })
    .then((result) => {

      this.logger.debug({ result }, 'fetchDiscs')

    })
    .catch((e) => {

      this.logger.error(e);

    });

  }

}
