import { ModelAbstract, ModelInterface } from "@digitaltoolbuilders/iso";
import { WOOD_DISC_PICTURE } from "../../const";
import { S3FileLocation } from "../../file";

export class WoodDiscPicture extends ModelAbstract implements WoodDiscPictureInterface {

  disc_id: string;

  location: S3FileLocation;

  readonly model_name = WOOD_DISC_PICTURE;

  picture_id: string;

  taken_epoch: number;

  constructor(input: Partial<Omit<WoodDiscPicture, 'model_name'>>) {

    super(input);

    if (input.disc_id) this.disc_id = input.disc_id;
    if (input.location) this.location = input.location;
    if (input.picture_id) this.picture_id = input.picture_id;
    if (input.taken_epoch) this.taken_epoch = input.taken_epoch;

  }

}

export interface WoodDiscPictureInterface extends ModelInterface {

  disc_id: string;

  location: S3FileLocation;

  picture_id: string;

  taken_epoch: number;

}
