import { FormControl, FormGroup, Validators } from "@angular/forms";
import { WoodDiscShape, WoodDiscStatus, WoodSpecies } from "@wam/models";

export class CreateForm extends FormGroup {

  constructor() {

    super({
      dimensions: new FormGroup({
        height_inches: new FormControl(undefined, [Validators.required]),
        thickness: new FormGroup({
          largest_inches: new FormControl(undefined, [Validators.required]),
          smallest_inches: new FormControl(undefined, [Validators.required]),
        }),
        weight_pounds: new FormControl(undefined, []),
        width_inches: new FormControl(undefined, [Validators.required]),
      }),
      disc_id: new FormControl<string>('', [Validators.required]),
      features: new FormGroup({
        arms: new FormControl<boolean | undefined>(undefined),
        dark_pith: new FormControl<boolean | undefined>(undefined),
        eyes: new FormControl<boolean | undefined>(undefined),
        holes: new FormControl<boolean | undefined>(undefined),
        horns: new FormControl<boolean | undefined>(undefined),
      }),
      has_pentacryl: new FormControl<boolean | undefined>(undefined),
      moisture_percentage: new FormControl<number | undefined>(undefined),
      shape: new FormControl<WoodDiscShape | string>('', [Validators.required]),
      species: new FormControl<WoodSpecies | string>('', [Validators.required]),
      status: new FormControl<WoodDiscStatus | string>('', [Validators.required]),
    });

  }

}
