import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sign-in',
  standalone: false,
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {

  constructor() {

    // debugger;

  }

}
