import { ChangeDetectionStrategy, Component, computed, Signal } from '@angular/core';
import { CreateForm } from '../create-form/create-form.form';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WoodDiscCreatePayload } from '@wam/models';
import { DiscService } from '../disc.service';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ng-wood-disc-create-dialog',
  standalone: false,
  templateUrl: './create-dialog.component.html',
  styleUrl: './create-dialog.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDialogComponent {

  form = new CreateForm();

  processing: Signal<boolean>;

  constructor(
    private dialogRef: MatDialogRef<CreateDialogComponent>,
    private logger: LoggerService,
    private service: DiscService,
    private snackbar: MatSnackBar,
  ) { 

    const discIdControl = this.form.get('disc_id') as FormControl;

    const discId$ = discIdControl.valueChanges.pipe(startWith(discIdControl.value));

    const discIdSignal = toSignal(discId$, {
      initialValue: discIdControl.value,
    });

    this.processing = computed(() => {

      const disc_id = discIdSignal();

      if (disc_id) {

        return this.service.select(disc_id)().processing;

      }

      return false;

    });

  }

  closeDialog() {

    this.dialogRef.close();

  }

  submit() {

    if (this.form.valid) {

      const payload: WoodDiscCreatePayload = {
        ...this.form.value,
      };

      this.service.create(payload)
      .then((disc) => {

        this.dialogRef.close(disc);

      })
      .catch((e) => {

        this.logger.error(e);

        this.snackbar.open('There was an error, please try again.', 'Dismiss');

      });

    } else {

      this.snackbar.open('The form is not valid, check for errors and try again.', 'Dismiss');

    }

  }

}
