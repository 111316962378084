import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardListComponent } from './card-list/card-list.component';
import { MatCardModule } from '@angular/material/card';
import { CardSummaryComponent } from './card-summary/card-summary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { CreateFormComponent } from './create-form/create-form.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { UpdateFormComponent } from './update-form/update-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CardListComponent,
    CardSummaryComponent,
    TableComponent,
    CreateFormComponent,
    CreateDialogComponent,
    UpdateFormComponent,
  ],
  exports: [
    CardListComponent,
    CardSummaryComponent,
    TableComponent,
    CreateFormComponent,
    CreateDialogComponent,
    UpdateFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    ReactiveFormsModule,
  ],
})
export class DiscModule {}
