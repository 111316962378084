import { ModelEventAbstract, ModelList } from "@digitaltoolbuilders/iso";
import { WOOD_DISC } from "../../const";
import { WoodDisc } from "./model";

export class WoodDiscCreatedEvent extends ModelEventAbstract<WoodDisc> {

  readonly event_name = WoodDiscEventNameMap.CREATED;

  readonly model_name = WOOD_DISC;

}

export class WoodDiscDeletedEvent extends ModelEventAbstract<WoodDisc & { deleted_epoch: number }> {

  readonly event_name = WoodDiscEventNameMap.DELETED;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscDeletedEvent, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscEvent = 
  | WoodDiscCreatedEvent
  | WoodDiscDeletedEvent
  | WoodDiscListedEvent
  | WoodDiscUpdatedEvent
  | WoodDiscViewedEvent;

export const WoodDiscEventNameMap = {
  CREATED: `${WOOD_DISC}:created`,
  DELETED: `${WOOD_DISC}:deleted`,
  LISTED: `${WOOD_DISC}:listed`,
  UPDATED: `${WOOD_DISC}:updated`,
  VIEWED: `${WOOD_DISC}:viewed`,
} as const;

export class WoodDiscListedEvent extends ModelEventAbstract<ModelList<WoodDisc>> {

  readonly event_name = WoodDiscEventNameMap.LISTED;

  readonly model_name = WOOD_DISC;

}

export type WoodDiscReadEvent =
  | WoodDiscListedEvent
  | WoodDiscViewedEvent;

export class WoodDiscUpdatedEvent extends ModelEventAbstract<WoodDisc> {

  readonly event_name = WoodDiscEventNameMap.UPDATED;

  readonly model_name = WOOD_DISC;

}

export class WoodDiscViewedEvent extends ModelEventAbstract<WoodDisc> {

  readonly event_name = WoodDiscEventNameMap.VIEWED;

  readonly model_name = WOOD_DISC;

}

export type WoodDiscWriteEvent =
  | WoodDiscCreatedEvent
  | WoodDiscDeletedEvent
  | WoodDiscUpdatedEvent;
