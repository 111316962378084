import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sign-out',
  standalone: false,
  templateUrl: './sign-out.component.html',
  styleUrl: './sign-out.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutComponent {}
