import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WoodDiscShapes, WoodDiscStatuses, WoodSpecies } from '@wam/models';

@Component({
  selector: 'ng-wood-disc-update-form',
  standalone: false,
  templateUrl: './update-form.component.html',
  styleUrl: './update-form.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateFormComponent {

  shapes = WoodDiscShapes;

  species = WoodSpecies;

  statuses = WoodDiscStatuses;

}
