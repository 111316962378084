import { ChangeDetectionStrategy, Component, computed, input, Signal, TemplateRef } from '@angular/core';
import { WoodDisc } from '@wam/models';
import { AnyTemplate } from '../../../common';

@Component({
  selector: 'ng-wood-disc-card-list',
  standalone: false,
  templateUrl: './card-list.component.html',
  styleUrl: './card-list.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListComponent {

  actions = input<TemplateRef<AnyTemplate>>();

  discs = input<Array<WoodDisc>>([]);

  empty = computed(() => {

    const discs = this.discs();

    return (discs.length === 0);

  });

}
