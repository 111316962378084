"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.address = exports.unit = exports.town = exports.street = exports.state = exports.postal_code = exports.country = exports.city = void 0;
const Joi = require("joi");
const defaults_1 = require("./defaults");
exports.city = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.country = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.postal_code = Joi.string().max(15);
exports.state = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.street = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.town = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.unit = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.address = Joi.object().keys({
  city: exports.city.required(),
  country: exports.country.required(),
  postal_code: exports.postal_code.required(),
  state: exports.state.required(),
  street: exports.street.required(),
  town: exports.town.optional().allow(null),
  unit: exports.unit.optional().allow(null)
});
