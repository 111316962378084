import * as i0 from '@angular/core';
import { signal, computed, Injectable } from '@angular/core';
import { produce } from 'immer';
import { customAlphabet, nanoid } from 'nanoid';
import { alphanumeric, numbers } from 'nanoid-dictionary';
import { v4 } from 'uuid';
function getEntityFromState(id, state) {
  const internal = state.entities[id] || {
    loading: false,
    processing: false
  };
  const entity = {
    id,
    ...internal
  };
  return entity;
}
class EntityFacade {
  createStateId;
  entitySignals = {};
  groupSignals = {};
  stateSignal = signal({
    entities: {},
    groups: {}
  });
  state = this.stateSignal.asReadonly();
  constructor(createStateId) {
    this.createStateId = createStateId;
  }
  selectEntity(id) {
    if (!this.entitySignals[id]) {
      this.entitySignals[id] = computed(() => {
        const state = this.state();
        return getEntityFromState(id, state);
      });
    }
    return this.entitySignals[id];
  }
  selectGroup(group) {
    if (!this.groupSignals[group]) {
      this.groupSignals[group] = computed(() => {
        const state = this.state();
        const internal = state.groups[group] || {
          ids: [],
          loading: false,
          processing: false
        };
        const models = internal.ids.map(id => getEntityFromState(id, state).model).filter(model => model ? true : false);
        const external = {
          ...internal,
          group,
          models
        };
        return external;
      });
    }
    return this.groupSignals[group];
  }
  updateEntity(props) {
    this.stateSignal.update(original => {
      return produce(original, state => {
        const {
          id,
          loaded,
          loading,
          model,
          processing
        } = props;
        const internal = state.entities[id] || {};
        internal.loaded = loaded;
        internal.loading = loading || false;
        internal.model = model;
        internal.processing = processing || false;
        state.entities[id] = internal;
      });
    });
  }
  updateGroup(props, now = Date.now()) {
    this.stateSignal.update(original => {
      return produce(original, state => {
        const {
          group,
          last,
          loaded,
          loading,
          models,
          processing
        } = props;
        const internal = state.groups[group] || {
          ids: []
        };
        internal.loading = loading || false;
        internal.processing = processing || false;
        if (models) {
          internal.last = last;
          internal.loaded = loaded || now;
          models.forEach(model => {
            const id = this.createStateId(model);
            const int = state.entities[id] || {
              loading: false,
              processing: false
            };
            int.loaded = internal.loaded;
            int.model = model;
            state.entities[id] = int;
          });
        }
        state.groups[group] = internal;
      });
    });
  }
}
class EntityService {
  createFacade(createStateIdFn) {
    return new EntityFacade(createStateIdFn);
  }
  static ɵfac = function EntityService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EntityService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: EntityService,
    factory: EntityService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EntityService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class IdService {
  alphanumeric(length = 10) {
    return customAlphabet(alphanumeric, length)();
  }
  nanoid(size) {
    return nanoid(size);
  }
  numeric(length = 10) {
    return customAlphabet(numbers, length)();
  }
  password(length = 16) {
    return customAlphabet(alphanumeric + '!@#$%^&*()', length)() + 'a1$';
  }
  uuid() {
    return v4();
  }
  static ɵfac = function IdService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IdService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IdService,
    factory: IdService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { EntityFacade, EntityService, IdService, getEntityFromState };
