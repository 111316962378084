import { ModelEventAbstract, ModelList } from "@digitaltoolbuilders/iso";
import { UserCognito } from "./model";
import { USER_COGNITO } from "../../const";

export class UserCognitoCreatedEvent extends ModelEventAbstract<UserCognito> {

  override readonly event_name = UserCognitoEventNameMap.CREATED;

  readonly model_name = USER_COGNITO;

}

export const UserCognitoEventNameMap = {
  CREATED: `${USER_COGNITO}:created`,
  LISTED: `${USER_COGNITO}:listed`,
  VIEWED: `${USER_COGNITO}:viewed`,
} as const;

export class UserCognitoListedEvent extends ModelEventAbstract<ModelList<UserCognito>> {

  override readonly event_name = UserCognitoEventNameMap.LISTED;

  readonly model_name = USER_COGNITO;

}

export class UserCognitoViewedEvent extends ModelEventAbstract<UserCognito> {

  override readonly event_name = UserCognitoEventNameMap.VIEWED;

  readonly model_name = USER_COGNITO;

}
