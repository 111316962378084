import { ModelAbstract, ModelInterface, ModelList } from "@digitaltoolbuilders/iso";
import { WOOD_DISC } from "../../const";
import { WoodSpecies } from "../species";

export class WoodDisc extends ModelAbstract implements WoodDiscInterface {

  dimensions: WoodDiscDimensions;

  disc_id: string;

  features: WoodDiscFeatures = {};

  has_pentacryl?: boolean;

  readonly model_name = WOOD_DISC;

  moisture_percentage?: number;

  shape: WoodDiscShape;

  species: WoodSpecies;

  starting: { dimensions: WoodDiscDimensions; moisture_percentage: number; };

  status: WoodDiscStatus;

  constructor(input: Partial<Omit<WoodDisc, 'model_name'>>) {

    super(input);

    if (input.dimensions) this.dimensions = input.dimensions;
    if (input.disc_id) this.disc_id = input.disc_id;
    if (input.features) this.features = input.features;
    if (input.has_pentacryl) this.has_pentacryl = input.has_pentacryl;
    if (input.moisture_percentage) this.moisture_percentage = input.moisture_percentage;
    if (input.shape) this.shape = input.shape;
    if (input.species) this.species = input.species;
    if (input.starting) this.starting = input.starting;
    if (input.status) this.status = input.status;

  }

}

export interface WoodDiscDimensions {

  height_inches: number;

  thickness: WoodDiscDimensionsThickness;

  weight_pounds: number;

  width_inches: number;

}

export interface WoodDiscDimensionsThickness {

  average_inches: number;

  largest_inches: number;

  smallest_inches: number;

}

export interface WoodDiscFeatures {

  arms?: boolean;

  dark_pith?: boolean;

  eyes?: boolean;

  holes?: boolean;

  horns?: boolean;

}

export interface WoodDiscInterface extends ModelInterface {

  dimensions: WoodDiscDimensions;

  disc_id: string;

  features: WoodDiscFeatures;

  has_pentacryl?: boolean;

  moisture_percentage?: number;

  shape: WoodDiscShape;

  species: WoodSpecies;

  starting: {
    dimensions: WoodDiscDimensions;
    moisture_percentage: number;
  };

  status: WoodDiscStatus;

}

export class WoodDiscList extends ModelList<WoodDisc> {

  

}

export const WoodDiscShapeMap = {
  CIRCLE: 'circle',
  EGG: 'egg',
  OVAL: 'oval',
} as const;

export type WoodDiscShape = typeof WoodDiscShapeMap[keyof typeof WoodDiscShapeMap];

export const WoodDiscShapes = Object.values(WoodDiscShapeMap);

export const WoodDiscStatusMap = {
  DRIED: 'dried',
  DRYING: 'drying',
} as const;

export type WoodDiscStatus = typeof WoodDiscStatusMap[keyof typeof WoodDiscStatusMap];

export const WoodDiscStatuses = Object.values(WoodDiscStatusMap);