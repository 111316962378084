"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModelList = exports.ModelEventAbstract = exports.ModelActionAbstract = exports.ModelAbstract = void 0;
class ModelAbstract {
  constructor(input) {
    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.deleted_epoch) this.deleted_epoch = input.deleted_epoch;
    if (input.last_updated_epoch) this.last_updated_epoch = input.last_updated_epoch;
  }
}
exports.ModelAbstract = ModelAbstract;
class ModelActionAbstract {
  constructor(input) {
    if (input.action_id) this.action_id = input.action_id;
    if (input.epoch) this.epoch = input.epoch;
    if (input.payload) this.payload = input.payload;
    if (input.user_id) this.user_id = input.user_id;
  }
}
exports.ModelActionAbstract = ModelActionAbstract;
class ModelEventAbstract {
  constructor(input) {
    if (input.action_id) this.action_id = input.action_id;
    if (input.epoch) this.epoch = input.epoch;
    if (input.event_id) this.event_id = input.event_id;
    if (input.payload) this.payload = input.payload;
    if (input.user_id) this.user_id = input.user_id;
  }
}
exports.ModelEventAbstract = ModelEventAbstract;
class ModelList {
  constructor(input) {
    this.count = 0;
    this.models = [];
    this.scanned = 0;
    if (input.count) this.count = input.count;
    if (input.last) this.last = input.last;
    if (input.models) this.models = input.models;
    if (input.scanned) this.scanned = input.scanned;
  }
}
exports.ModelList = ModelList;
