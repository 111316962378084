import { ChangeDetectionStrategy, Component, input, TemplateRef } from '@angular/core';
import { AnyTemplate } from '../../../common';
import { WoodDisc } from '@wam/models';

@Component({
  selector: 'ng-wood-disc-card-summary',
  standalone: false,
  templateUrl: './card-summary.component.html',
  styleUrl: './card-summary.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSummaryComponent {

  actions = input<TemplateRef<AnyTemplate>>();

  disc = input<WoodDisc | undefined>(undefined);

}
