"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountryCodes = exports.CountryCodeMap = void 0;
exports.CountryCodeMap = {
  CAN: 'CAN',
  USA: 'USA'
};
exports.CountryCodes = Object.values(exports.CountryCodeMap);
