import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { WoodDiscShapes, WoodDiscStatuses, WoodSpecies } from '@wam/models';
import { CreateForm } from './create-form.form';

@Component({
  selector: 'ng-wood-disc-create-form',
  standalone: false,
  templateUrl: './create-form.component.html',
  styleUrl: './create-form.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFormComponent {

  form = input<CreateForm>(new CreateForm());

  shapes = WoodDiscShapes;

  species = WoodSpecies;

  statuses = WoodDiscStatuses;

}
