<div class="flex flex-col">

  <div class="flex flex-row justify-between">

    <h1 class="text-xl">Walnut And Metal</h1>

    <div class="flex flex-row justify-between">

      <button type="button" color="primary"
        *ngIf="!isAuthed()"
        (click)="signIn()"
        mat-raised-button>Sign In</button>

      <div class="flex flex-row justify-center"
        *ngIf="isAuthed()">

        <span>Authed</span>

      </div>

      <button type="button" color="warn"
        *ngIf="isAuthed()"
        (click)="signOut()"
        mat-raised-button>Sign Out</button>

    </div>

  </div>

  <div class="flex flex-col"
    *ngIf="isAuthed()">

    <pre>{{authed() | json}}</pre>

  </div>

  <div class="flex flex-col">

    <router-outlet></router-outlet>

  </div>

</div>
