import { inject, Injectable, Signal } from '@angular/core';
import { DiscServiceAdapter } from './disc.service.adapter';
import { BACKEND_ADAPTER, BackendAdapterMap } from '../../backend-adapter';
import { CredentialsService } from '@digitaltoolbuilders/ng-auth';
import { woodDisc as schemas } from '@wam/schemas';
import { props } from 'bluebird';
import { WoodDisc, WoodDiscCreateAction, WoodDiscCreatePayload, WoodDiscGetAction, WoodDiscGetPayload, WoodDiscListAction, WoodDiscListPayload } from '@wam/models';
import { EntityFacade, EntityService, IdService, ModelEntityGroup } from '@digitaltoolbuilders/ng-helpers';
import { LastEvaluatedKey } from '@digitaltoolbuilders/iso';
import { RestServiceAdapter } from './rest.service.adapter';
import { WebsocketServiceAdapter } from './websocket.service.adapter';

export function createStateId(model: Pick<WoodDisc, 'disc_id'>) {

  return `${model.disc_id}`;

}

@Injectable({
  providedIn: 'root'
})
export class DiscService {

  private adapter: DiscServiceAdapter;

  all: Signal<ModelEntityGroup<WoodDisc>>;

  private facade: EntityFacade<WoodDisc, LastEvaluatedKey>;

  constructor(
    private creds: CredentialsService,
    entity: EntityService,
    private id: IdService,
  ) { 

    const backendAdapter = inject(BACKEND_ADAPTER);

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestServiceAdapter);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketServiceAdapter);

        break;

      default:

        throw new Error(`unknown adapter: ${backendAdapter}`);

    }

    this.facade = entity.createFacade<WoodDisc, LastEvaluatedKey>(createStateId);

    this.all = this.facade.selectGroup('all');

  }

  create(data: Partial<WoodDiscCreatePayload>, now = Date.now()) {

    return schemas.createPayload.validateAsync(data, {})
    .then((payload) => {

      const id = createStateId(payload);

      this.facade.updateEntity({
        id,
        processing: true,
      });

      return props({
        credentials: this.creds.waitFor(),
        id,
        payload,
      });

    })
    .then(({ id, payload }) => {

      const action = new WoodDiscCreateAction({
        action_id: this.id.uuid(),
        epoch: now,
        payload,
      });

      return this.adapter.create(action)
      .then((event) => {
  
        const model = event.payload;
  
        const id = createStateId(model);
  
        this.facade.updateEntity({
          id,
          model,
          processing: false,
        });
  
        return model;
  
      })
      .catch((e) => {

        this.facade.updateEntity({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  get(data: Partial<WoodDiscGetPayload>, now = Date.now()) {

    return schemas.getPayload.validateAsync(data, {})
    .then((payload) => {

      const id = createStateId(payload);

      this.facade.updateEntity({
        id,
        loading: true,
      });

      return props({
        cred: this.creds.waitFor(),
        id,
        payload,
      });

    })
    .then(({ id, payload }) => {

      return this.adapter.get(new WoodDiscGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        payload,
      }))
      .then((event) => {

        const model = event.payload;

        const id = createStateId(model);

        this.facade.updateEntity({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.facade.updateEntity({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: Partial<WoodDiscListPayload>, group = 'all', now = Date.now()) {

    return schemas.listPayload.validateAsync(data, {})
    .then((payload) => {

      this.facade.updateGroup({
        group,
        loading: true,
      });

      return props({
        payload,
        user_id: this.creds.waitFor().then((creds) => creds.identityId),
      });

    })
    .then(({ payload, user_id }) => {

      const action = new WoodDiscListAction({
        action_id: this.id.uuid(),
        epoch: now,
        payload,
        user_id,
      });

      return this.adapter.list(action);

    })
    .then((event) => {

      const list = event.payload;

      this.facade.updateGroup({
        group,
        last: list.last,
        loading: false,
        models: list.models,
      })

      return list;

    })
    .catch((e) => {

      this.facade.updateGroup({
        e,
        group,
        loading: false,
      });

      throw e;

    });

  }

  select(disc_id: string) {

    return this.facade.selectEntity(createStateId({ disc_id }));

  }

}
