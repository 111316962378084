"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertAxiosErrorToError = convertAxiosErrorToError;
exports.convertAxiosErrorToModelEvent = convertAxiosErrorToModelEvent;
exports.convertModelEventToError = convertModelEventToError;
const acl_1 = require("@digitaltoolbuilders/acl");
const joi_1 = require("joi");
const exceptions_1 = require("./exceptions");
const logging_1 = require("@digitaltoolbuilders/logging");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertAxiosErrorToError(e) {
  var _a;
  let converted = e;
  if (e.response) {
    let event = e.response.data;
    converted = new Error(((_a = event.payload) === null || _a === void 0 ? void 0 : _a.message) || e.response.data.message);
    if (e.response.status === 400) {
      event = e.response.data;
      converted = new joi_1.ValidationError(event.payload.message, event.payload.details, event.payload._original);
    } else if (e.response.status === 403) {
      event = e.response.data;
      converted = new acl_1.NotAllowed(event.payload.message);
    } else if (e.response.status === 404) {
      event = e.response.data;
      converted = new exceptions_1.NotFoundException(event.payload.message);
    } else if (e.response.status === 409) {
      converted = new exceptions_1.ConditionalCheckFailedException();
    }
  }
  logging_1.logger.debug({
    e,
    event,
    converted
  }, 'convertAxiosErrorToError');
  return converted;
}
function convertAxiosErrorToModelEvent(e) {
  logging_1.logger.debug({
    e
  }, 'convertAxiosErrorToModelEvent');
  if (e.response) {
    if (e.response.status === 400) {
      return new exceptions_1.NotValidEvent(e.response.data);
    } else if (e.response.status === 403) {
      return new exceptions_1.NotAllowedEvent(e.response.data);
    } else if (e.response.status === 404) {
      return new exceptions_1.NotFoundEvent(e.response.data);
    } else if (e.response.status === 409) {
      return new exceptions_1.ConditionalCheckFailedEvent(e.response.data);
    } else {
      return new exceptions_1.ErrorEvent(e.response.data);
    }
  } else {
    return new exceptions_1.ErrorEvent({
      payload: e
    });
  }
}
function convertModelEventToError(e) {
  switch (e.event_name) {
    case exceptions_1.ExceptionEventNameMap.CONDITIONAL_EXCEPTION:
      return new exceptions_1.ConditionalCheckFailedException();
    case exceptions_1.ExceptionEventNameMap.ERROR:
      return new Error(e.payload.message);
    case exceptions_1.ExceptionEventNameMap.NOT_ALLOWED:
      return new acl_1.NotAllowed(e.payload.message);
    case exceptions_1.ExceptionEventNameMap.NOT_FOUND:
      return new exceptions_1.NotFoundException(e.payload.message);
    case exceptions_1.ExceptionEventNameMap.NOT_VALID:
      return new joi_1.ValidationError(e.payload.message, e.payload.details, e.payload.original || e.payload._original);
    default:
      return new Error(e.payload.message);
  }
}
