import { ConsistentReadInterface, ExclusiveStartKeyInterface, LimitInterface, ModelActionAbstract } from "@digitaltoolbuilders/iso";
import { WOOD_DISC } from "../../const";
import { WoodDisc } from "./model";

export type WoodDiscAction = 
  | WoodDiscCreateAction
  | WoodDiscDeleteAction
  | WoodDiscGetAction
  | WoodDiscListAction
  | WoodDiscUpdateAction;

export const WoodDiscActionNameMap = {
  CREATE: `${WOOD_DISC}/create`,
  DELETE: `${WOOD_DISC}/delete`,
  GET: `${WOOD_DISC}/get`,
  LIST: `${WOOD_DISC}/list`,
  UPDATE: `${WOOD_DISC}/update`,
} as const;

export class WoodDiscCreateAction extends ModelActionAbstract<WoodDiscCreatePayload> {

  readonly action_name = WoodDiscActionNameMap.CREATE;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscCreateAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscCreatePayload = Omit<WoodDisc, 'last_updated_epoch' | 'model_name'>;

export class WoodDiscDeleteAction extends ModelActionAbstract<WoodDiscDeletePayload> {

  readonly action_name = WoodDiscActionNameMap.DELETE;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscDeleteAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscDeletePayload = Pick<WoodDisc, 'disc_id' | 'last_updated_epoch'>;

export class WoodDiscGetAction extends ModelActionAbstract<WoodDiscGetPayload> {

  readonly action_name = WoodDiscActionNameMap.GET;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscGetAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscGetPayload = Pick<WoodDisc, 'disc_id'> & ConsistentReadInterface;

export class WoodDiscListAction extends ModelActionAbstract<WoodDiscListPayload> {

  readonly action_name = WoodDiscActionNameMap.LIST;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscListAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscListPayload = Partial<Pick<WoodDisc, 'shape' | 'species' | 'status'>>
  & ExclusiveStartKeyInterface
  & LimitInterface;

export type WoodDiscReadAction = 
  | WoodDiscGetAction 
  | WoodDiscListAction;

export class WoodDiscUpdateAction extends ModelActionAbstract<WoodDiscUpdatePayload> {

  readonly action_name = WoodDiscActionNameMap.UPDATE;

  readonly model_name = WOOD_DISC;

  constructor(input: Partial<Omit<WoodDiscUpdateAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscUpdatePayload = Omit<WoodDisc, 'created_epoch' | 'deleted_epoch'>;

export type WoodDiscWriteAction = 
  | WoodDiscCreateAction
  | WoodDiscDeleteAction
  | WoodDiscUpdateAction;
