import { Injectable } from '@angular/core';
import { RestService } from '@digitaltoolbuilders/ng-api';
import { DiscServiceAdapter } from './disc.service.adapter';
import { WOOD_DISC, WoodDisc, WoodDiscCreateAction, WoodDiscCreatedEvent, WoodDiscGetAction, WoodDiscInterface, WoodDiscListAction, WoodDiscListedEvent, WoodDiscUpdateAction, WoodDiscUpdatedEvent, WoodDiscViewedEvent } from '@wam/models';
import { ModelList, RestServerResponse } from '@digitaltoolbuilders/iso';

@Injectable({
  providedIn: 'root'
})
export class RestServiceAdapter implements DiscServiceAdapter {

  readonly path = `/${WOOD_DISC}`;

  constructor(private rest: RestService) { }

  create(action: WoodDiscCreateAction): Promise<WoodDiscCreatedEvent> {
    
    return this.rest.post<RestServerResponse<WoodDiscInterface>>(this.path, action)
    .then((response) => {

      return new WoodDiscCreatedEvent({
        ...response.data,
        payload: new WoodDisc(response.data.payload),
      });

    });

  }

  get(action: WoodDiscGetAction): Promise<WoodDiscViewedEvent> {
      
    return this.rest.post<RestServerResponse<WoodDiscInterface>>(this.path, action)
    .then((response) => {

      return new WoodDiscViewedEvent({
        ...response.data,
        payload: new WoodDisc(response.data.payload),
      });

    });

  }

  list(action: WoodDiscListAction): Promise<WoodDiscListedEvent> {
    
    return this.rest.post<RestServerResponse<ModelList<WoodDiscInterface>>>(this.path, action)
    .then((response) => {

      return new WoodDiscListedEvent({
        ...response.data,
        payload: new ModelList<WoodDisc>({
          ...response.data.payload,
          models: response.data.payload.models.map((item) => new WoodDisc(item)),
        }),
      });

    });

  }

  update(action: WoodDiscUpdateAction): Promise<WoodDiscUpdatedEvent> {
    
    return this.rest.post<RestServerResponse<WoodDiscInterface>>(this.path, action)
    .then((response) => {

      return new WoodDiscUpdatedEvent({
        ...response.data,
        payload: new WoodDisc(response.data.payload),
      });

    });

  }

}
