"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_LOCALE = exports.Locales = exports.LocaleMap = void 0;
exports.LocaleMap = {
  AR: 'ar',
  BG: 'bg',
  CS: 'cs',
  DA: 'da',
  DE: 'de',
  EL: 'el',
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  ES: 'es',
  ES_419: 'es-419',
  ET: 'et',
  FI: 'fi',
  FR: 'fr',
  FR_CA: 'fr-CA',
  HE: 'he',
  HR: 'hr',
  HU: 'hu',
  ID: 'id',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  LT: 'lt',
  LV: 'lv',
  MS: 'ms',
  MT: 'mt',
  NB: 'nb',
  NL: 'nl',
  PL: 'pl',
  PT_BR: 'pt-BR',
  PT: 'pt',
  RO: 'ro',
  RU: 'ru',
  SK: 'sk',
  SL: 'sl',
  SV: 'sv',
  TH: 'th',
  TR: 'tr',
  VI: 'vi',
  ZH: 'zh',
  ZH_HK: 'zh-HK',
  ZH_TW: 'zh-TW'
};
exports.Locales = Object.values(exports.LocaleMap);
exports.DEFAULT_LOCALE = exports.LocaleMap.EN_US;
