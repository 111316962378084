import { ModelAbstract, ModelInterface } from "@digitaltoolbuilders/iso";
import { USER } from "../const";

export class User extends ModelAbstract implements UserInterface {

  birthdate: string;

  email: string;

  email_verified = false;

  family_name?: string;

  given_name?: string;

  local_time_zone: string;

  middle_name?: string;

  readonly model_name = USER;

  name: string;

  phone_number: string;

  phone_number_verified = false;

  picture?: string;

  roles: Array<UserRole> = [];

  status: UserStatus;

  user_id: string;

  constructor(input: Partial<Omit<User, 'model_name'>>) {

    super(input);

    if (input.birthdate) this.birthdate = input.birthdate;
    if (input.email) this.email = input.email;
    if (input.email_verified) this.email_verified = input.email_verified;
    if (input.family_name) this.family_name = input.family_name;
    if (input.given_name) this.given_name = input.given_name;
    if (input.local_time_zone) this.local_time_zone = input.local_time_zone;
    if (input.middle_name) this.middle_name = input.middle_name;
    if (input.name) this.name = input.name;
    if (input.phone_number) this.phone_number = input.phone_number;
    if (input.phone_number_verified) this.phone_number_verified = input.phone_number_verified;
    if (input.picture) this.picture = input.picture;
    if (input.roles) this.roles = input.roles;
    if (input.status) this.status = input.status;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export interface UserInterface extends ModelInterface {

  birthdate: string;

  email: string;

  email_verified: boolean;

  family_name?: string;

  given_name?: string;

  local_time_zone: string;

  middle_name?: string;

  name: string;

  phone_number: string;

  phone_number_verified: boolean;

  picture?: string;

  roles: Array<UserRole>;

  status: UserStatus;

  user_id: string;

}

export const UserRoleMap = {
  ADMIN: 'admin',
  ANONYMOUS: 'anonymous',
  USER: 'user',
} as const;

export type UserRole = typeof UserRoleMap[keyof typeof UserRoleMap];

export const UserRoles = Object.values(UserRoleMap);

export const UserStatusMap = {
  ACTIVE: 'active',
  BANNED: 'banned',
  INACTIVE: 'inactive',
  SUSPENDED: 'suspended',
  UNREGISTERED: 'unregistered',
} as const;

export type UserStatus = typeof UserStatusMap[keyof typeof UserStatusMap];

export const UserStatuses = Object.values(UserStatusMap);
