import { ModelEventAbstract, ModelList } from "@digitaltoolbuilders/iso";
import { USER } from "../const";
import { User } from "./model";

export const UserEventNameMap = {
  CREATED: `${USER}:created`,
  FOUND: `${USER}:found`,
  LISTED: `${USER}:listed`,
  REGISTERED: `${USER}:registered`,
  SIGNED_UP: `${USER}:sign-up`,
  UPDATED: `${USER}:updated`,
  VIEWED: `${USER}:viewed`,
} as const;

export class UserCreatedEvent extends ModelEventAbstract<User> {

  readonly event_name = UserEventNameMap.CREATED;

  readonly model_name = USER;

}

export class UserFoundEvent extends ModelEventAbstract<User> {

  readonly event_name = UserEventNameMap.FOUND;

  readonly model_name = USER;

}

export class UserListedEvent extends ModelEventAbstract<ModelList<User>> {

  override readonly event_name = UserEventNameMap.LISTED;

  readonly model_name = USER;

  constructor(input: Partial<Omit<UserListedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}

export class UserRegisteredEvent extends ModelEventAbstract<User> {

  readonly event_name = UserEventNameMap.REGISTERED;

  readonly model_name = USER;

}

export class UserSignedUpEvent extends ModelEventAbstract<User> {

  readonly event_name = UserEventNameMap.SIGNED_UP;

  readonly model_name = USER;

}

export class UserUpdatedEvent extends ModelEventAbstract<User> {

  override readonly event_name = UserEventNameMap.UPDATED;

  readonly model_name = USER;

}

export class UserViewedEvent extends ModelEventAbstract<User> {

  override readonly event_name = UserEventNameMap.VIEWED;

  readonly model_name = USER;

}
