import { ConsistentReadInterface, ExclusiveStartKeyInterface, LimitInterface, ModelActionAbstract } from "@digitaltoolbuilders/iso";
import { USER_COGNITO } from "../../const";
import { UserCognito } from "./model";

export const UserCognitoActionNameMap = {
  CREATE: `${USER_COGNITO}/create`,
  FIND: `${USER_COGNITO}/find`,
  GET: `${USER_COGNITO}/get`,
  LIST: `${USER_COGNITO}/list`,
  UPDATE: `${USER_COGNITO}/update`,
} as const;

export class UserCognitoCreateAction extends ModelActionAbstract<UserCognitoCreatePayload> {

  override readonly action_name = UserCognitoActionNameMap.CREATE;

  readonly model_name = USER_COGNITO;

}

export type UserCognitoCreatePayload = Omit<UserCognito, 
  | 'created_epoch' 
  | 'last_updated_epoch'> 
  & Partial<Pick<UserCognito, 'created_epoch'>>;

  export class UserCognitoGetAction extends ModelActionAbstract<UserCognitoGetPayload> {

    override readonly action_name = UserCognitoActionNameMap.GET;
  
    readonly model_name = USER_COGNITO;
  
  }
  
  export type UserCognitoGetPayload = Pick<UserCognito, 'user_name' | 'user_pool_id'> 
    & ConsistentReadInterface;
  
export class UserCognitoListAction extends ModelActionAbstract<UserCognitoListPayload> {

  override readonly action_name = UserCognitoActionNameMap.LIST;

  readonly model_name = USER_COGNITO;

}

export type UserCognitoListPayload = Partial<Pick<UserCognito, 'alias' | 'user_id' | 'user_pool_id'>> 
  & ExclusiveStartKeyInterface
  & LimitInterface;
