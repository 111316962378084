"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteNotFoundEvent = exports.NotValidEvent = exports.NotFoundEvent = exports.NotAllowedEvent = exports.ExceptionEventNameMap = exports.ConditionalCheckFailedEvent = exports.RouteNotFoundException = exports.NotFoundException = exports.InvalidRequestException = exports.ErrorEvent = exports.ConditionalCheckFailedException = void 0;
const model_1 = require("./model");
class ConditionalCheckFailedException extends Error {
  constructor() {
    super('Conditional Check Failed');
    this.isConditional = true;
    this.name = 'ConditionalCheckFailedException';
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
exports.ConditionalCheckFailedException = ConditionalCheckFailedException;
class ErrorEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.ERROR;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.ErrorEvent = ErrorEvent;
class InvalidRequestException extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
    this.name = 'InvalidRequestException';
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
exports.InvalidRequestException = InvalidRequestException;
class NotFoundException extends Error {
  constructor(message) {
    super(message);
    this.isNotFound = true;
    this.name = 'NotFoundException';
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
exports.NotFoundException = NotFoundException;
class RouteNotFoundException extends Error {
  constructor(action) {
    super(`WebSocket Route Not Found: ${action.action_name}`);
    this.name = 'WebSocketRouteNotFound';
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
exports.RouteNotFoundException = RouteNotFoundException;
class ConditionalCheckFailedEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.CONDITIONAL_EXCEPTION;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.ConditionalCheckFailedEvent = ConditionalCheckFailedEvent;
exports.ExceptionEventNameMap = {
  CONDITIONAL_EXCEPTION: 'conditional-exception',
  ERROR: 'error',
  NOT_ALLOWED: 'not-allowed',
  NOT_FOUND: 'not-found',
  NOT_VALID: 'not-valid',
  ROUTE_NOT_FOUND: 'route-not-found'
};
class NotAllowedEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.NOT_ALLOWED;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.NotAllowedEvent = NotAllowedEvent;
class NotFoundEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.NOT_FOUND;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.NotFoundEvent = NotFoundEvent;
class NotValidEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.NOT_VALID;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.NotValidEvent = NotValidEvent;
class RouteNotFoundEvent extends model_1.ModelEventAbstract {
  constructor(input) {
    super(input);
    this.event_name = exports.ExceptionEventNameMap.ROUTE_NOT_FOUND;
    if (input.model_name) this.model_name = input.model_name;
  }
}
exports.RouteNotFoundEvent = RouteNotFoundEvent;
