
import { ModelAbstract, ModelInterface } from "@digitaltoolbuilders/iso";
import { USER_SESSION } from "../../const";

export class UserSession extends ModelAbstract implements UserSessionInterface {

  closed_epoch?: number;

  cognito_username: string;

  cognito_user_pool_id: string;

  readonly model_name = USER_SESSION;

  opened_epoch: number;

  session_id: string;

  status: UserSessionStatus;

  type: UserSessionType;

  user_id: string;

  constructor(input: Partial<Omit<UserSession, 'model_name'>>) {

    super(input);

    if (input.closed_epoch) this.closed_epoch = input.closed_epoch;
    if (input.cognito_username) this.cognito_username = input.cognito_username;
    if (input.cognito_user_pool_id) this.cognito_user_pool_id = input.cognito_user_pool_id;
    if (input.opened_epoch) this.opened_epoch = input.opened_epoch;
    if (input.session_id) this.session_id = input.session_id;
    if (input.status) this.status = input.status;
    if (input.type) this.type = input.type;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export interface UserSessionInterface extends ModelInterface {

  closed_epoch?: number;

  cognito_username: string;

  cognito_user_pool_id: string;

  opened_epoch: number;

  session_id: string;

  status: UserSessionStatus;

  type: UserSessionType;

  user_id: string;

}

export const UserSessionStatusMap = {
  CLOSED: 'closed',
  OPEN: 'open',
  TERMINATED: 'terminated',
} as const;

export type UserSessionStatus = typeof UserSessionStatusMap[keyof typeof UserSessionStatusMap];

export const UserSessionStatuses = Object.values(UserSessionStatusMap);

export const UserSessionTypeMap = {
  REST: 'rest',
  WEBSOCKET: 'websocket',
} as const;

export type UserSessionType = typeof UserSessionTypeMap[keyof typeof UserSessionTypeMap];

export const UserSessionTypes = Object.values(UserSessionTypeMap);
