import { ConsistentReadInterface, ExclusiveStartKeyInterface, LimitInterface, ModelActionAbstract } from "@digitaltoolbuilders/iso";
import { WOOD_DISC_PICTURE } from "../../const";
import { WoodDiscPicture } from "./model";

export const WoodDiscPictureActionNameMap = {
  CREATE: `${WOOD_DISC_PICTURE}/create`,
  DELETE: `${WOOD_DISC_PICTURE}/delete`,
  GET: `${WOOD_DISC_PICTURE}/get`,
  LIST: `${WOOD_DISC_PICTURE}/list`,
  UPDATE: `${WOOD_DISC_PICTURE}/update`,
} as const;

export class WoodDiscPictureCreateAction extends ModelActionAbstract<WoodDiscPictureCreatePayload> {

  readonly action_name = WoodDiscPictureActionNameMap.CREATE;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureCreateAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscPictureCreatePayload = Omit<WoodDiscPicture, 'deleted_epoch' | 'last_updated_epoch'>;

export class WoodDiscPictureDeleteAction extends ModelActionAbstract<WoodDiscPictureDeletePayload> {

  readonly action_name = WoodDiscPictureActionNameMap.DELETE;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureDeleteAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscPictureDeletePayload = Pick<WoodDiscPicture, 'disc_id' | 'last_updated_epoch' | 'picture_id'>;

export class WoodDiscPictureGetAction extends ModelActionAbstract<WoodDiscPictureGetPayload> {

  readonly action_name = WoodDiscPictureActionNameMap.GET;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureGetAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscPictureGetPayload = Pick<WoodDiscPicture, 'disc_id' | 'picture_id'>
  & ConsistentReadInterface;

export class WoodDiscPictureListAction extends ModelActionAbstract<WoodDiscPictureListPayload> {

  readonly action_name = WoodDiscPictureActionNameMap.LIST;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureListAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscPictureListPayload = Partial<Pick<WoodDiscPicture, 'disc_id'>>
  & ExclusiveStartKeyInterface
  & LimitInterface;

export class WoodDiscPictureUpdateAction extends ModelActionAbstract<WoodDiscPictureUpdatePayload> {

  readonly action_name = WoodDiscPictureActionNameMap.UPDATE;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureUpdateAction, 'action_name' | 'model_name'>>) {

    super(input);

  }

}

export type WoodDiscPictureUpdatePayload = Omit<WoodDiscPicture, 'created_epoch' | 'deleted_epoch'>;
