<form 
  [formGroup]="form"
  (ngSubmit)="submit()">

  <h1 mat-dialog-title>Create Wood Disc</h1>

  <mat-dialog-content>

    <ng-wood-disc-create-form [form]="form"></ng-wood-disc-create-form>

  </mat-dialog-content>

  <mat-dialog-actions class="flex flex-row justify-between">

    <button type="button" color="warn"
      (click)="closeDialog()"
      [disabled]="processing()"
      mat-raised-button>Cancel, Don't Create</button>

    <mat-spinner *ngIf="processing()" diameter="30"></mat-spinner>

    <button type="submit" color="primary"
      (click)="closeDialog()"
      [disabled]="form.invalid || processing()"
      mat-raised-button>Create Disc</button>

  </mat-dialog-actions>

</form>
