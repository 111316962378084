<div class="flex flex-row">

  <div class="flex flex-col"
    *ngIf="isAuthed()">

    <span>{{authed()?.name}}</span>

  </div>

  <div class="flex flex-row"
    *ngIf="!isAuthed()">

    <button type="button"
      (click)="signIn()"
      mat-icon-button><mat-icon>login</mat-icon></button>

  </div>

  <div class="flex flex-row"
    *ngIf="isAuthed()">

    <button type="button"
      (click)="signOut()"
      mat-icon-button><mat-icon>logout</mat-icon></button>

  </div>

</div>