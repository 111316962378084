import { UserCognitoActionNameMap, UserCognitoCreateAction, UserCognitoCreatePayload, UserCognitoGetAction, UserCognitoGetPayload, UserCognitoListAction, UserCognitoListPayload, UserCognitoStatuses } from "@wam/models";
import { consistent_read, createModelActionSchema, epoch, exclusive_start_key, limit, user_id } from "@digitaltoolbuilders/schema-helpers";
import Joi from "joi";

export const alias = Joi.string();

export const region = Joi.string();

export const state = Joi.string().allow(...UserCognitoStatuses);

export const user_name = Joi.string();

export const user_pool_id = Joi.string();

export const createPayload = Joi.object<UserCognitoCreatePayload>()
  .keys({
    alias: alias.required(),
    last_login_epoch: epoch.optional().allow(null),
    region: region.required(),
    status: state.required(),
    user_id: user_id.required(),
    user_name: user_name.required(),
    user_pool_id: user_pool_id.required(),
  });

export const createAction = createModelActionSchema<UserCognitoCreateAction, UserCognitoCreatePayload>(UserCognitoActionNameMap.CREATE, createPayload);

export const getPayload = Joi.object<UserCognitoGetPayload>()
  .keys({
    ConsistentRead: consistent_read,
    user_name: user_name.required(),
    user_pool_id: user_pool_id.required(),
  });

export const getAction = createModelActionSchema<UserCognitoGetAction, UserCognitoGetPayload>(UserCognitoActionNameMap.GET, getPayload);

export const listPayload = Joi.object<UserCognitoListPayload>()
  .keys({
    alias: alias.optional().allow(null),
    ExclusiveStartKey: exclusive_start_key,
    Limit: limit,
    user_id: user_id.optional().allow(null),
    user_pool_id: user_pool_id.optional().allow(null),
  });

export const listAction = createModelActionSchema<UserCognitoListAction, UserCognitoListPayload>(UserCognitoActionNameMap.LIST, listPayload);