import { DeletedModelInterface, ModelEventAbstract, ModelList } from "@digitaltoolbuilders/iso";
import { WOOD_DISC_PICTURE } from "../../const";
import { WoodDiscPicture } from "./model";

export class WoodDiscPictureCreatedEvent extends ModelEventAbstract<WoodDiscPicture> {

  readonly event_name = WoodDiscPictureEventNameMap.CREATED;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureCreatedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}

export class WoodDiscPictureDeletedEvent extends ModelEventAbstract<WoodDiscPicture & DeletedModelInterface> {

  readonly event_name = WoodDiscPictureEventNameMap.DELETED;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureDeletedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}

export const WoodDiscPictureEventNameMap = {
  CREATED: `${WOOD_DISC_PICTURE}:created`,
  DELETED: `${WOOD_DISC_PICTURE}:deleted`,
  LISTED: `${WOOD_DISC_PICTURE}:listed`,
  UPDATED: `${WOOD_DISC_PICTURE}:updated`,
  VIEWED: `${WOOD_DISC_PICTURE}:viewed`,
} as const;

export class WoodDiscPictureListedEvent extends ModelEventAbstract<ModelList<WoodDiscPicture>> {

  readonly event_name = WoodDiscPictureEventNameMap.LISTED;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureListedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}

export class WoodDiscPictureUpdatedEvent extends ModelEventAbstract<WoodDiscPicture> {

  readonly event_name = WoodDiscPictureEventNameMap.UPDATED;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureUpdatedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}

export class WoodDiscPictureViewedEvent extends ModelEventAbstract<WoodDiscPicture> {

  readonly event_name = WoodDiscPictureEventNameMap.VIEWED;

  readonly model_name = WOOD_DISC_PICTURE;

  constructor(input: Partial<Omit<WoodDiscPictureViewedEvent, 'event_name' | 'model_name'>>) {

    super(input);

  }

}
