import { inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  ConfigService,
  NG_CONFIG_DEFAULT_REGION,
  provideConfigInitializer,
} from '@digitaltoolbuilders/ng-config';
import { AWSRegionMap } from '@digitaltoolbuilders/iso';
import { LoggerService, PINO_LOG_LEVEL } from '@digitaltoolbuilders/ng-logging';
import { HomepageComponent } from './homepage/homepage.component';
import { WoodModule } from './wood/wood.module';
import { BACKEND_ADAPTER, BackendAdapterMap } from '@wam/ng-models';
import { provideHttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import {
  NgAuthModule,
  provideNgAuthConfig,
} from '@digitaltoolbuilders/ng-auth';
import { AppConfig } from './common';
import { DEFAULT_REST_API_NAME } from '@digitaltoolbuilders/ng-api';
import { AuthedUserComponent } from './authed-user/authed-user.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HomepageComponent,
    SignInComponent,
    SignOutComponent,
    AuthedUserComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatIconModule,
    NgAuthModule,
    RouterModule.forRoot(appRoutes),
    WoodModule,
  ],
  providers: [
    {
      provide: PINO_LOG_LEVEL,
      useValue: 'debug',
    },
    {
      provide: NG_CONFIG_DEFAULT_REGION,
      useValue: AWSRegionMap.US_WEST_2,
    },
    {
      provide: BACKEND_ADAPTER,
      useValue: BackendAdapterMap.REST,
    },
    {
      provide: DEFAULT_REST_API_NAME,
      useValue: 'marketing-tool',
    },
    provideNgAuthConfig(() => {
      const logger = inject(LoggerService);

      const service = inject(ConfigService<AppConfig>);

      logger.debug({}, 'provideNgAuthConfig');

      return service.config$
        .pipe(filter((config): config is AppConfig => (config ? true : false)))
        .pipe(
          map((config) => {
            return config.Auth;
          })
        )
        .pipe(
          map((config) => {
            config.signInUrl = `${location.protocol}//${location.host}/sign-in`;
            config.signOutUrl = `${location.protocol}//${location.host}/sign-out`;

            config.loginUrl = config.signInUrl;
            config.logoutUrl = config.signOutUrl;

            logger.debug({ config }, 'added urls');

            return config;
          })
        );
    }),
    provideConfigInitializer<AppConfig>(),
    provideHttpClient(),
  ],
})
export class AppModule {}
