import * as i0 from '@angular/core';
import { InjectionToken, makeEnvironmentProviders, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as i2 from '@digitaltoolbuilders/ng-auth';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import axios from 'axios';
import { BehaviorSubject, map, filter, Subject, timeout, firstValueFrom } from 'rxjs';
import * as i1 from '@digitaltoolbuilders/ng-config';
import { stringify } from 'qs';
import { HttpMethodMap, convertAxiosErrorToError, HttpHeadersMap, COGNITO_IDENTITY_TOKEN_QUERY_PARAM } from '@digitaltoolbuilders/iso';
import { Signer } from '@aws-amplify/core/internals/utils';
import { minutesToMilliseconds } from 'date-fns';
import { presignUrl } from '@aws-amplify/core/internals/aws-client-utils';
const DEFAULT_REST_API_NAME = new InjectionToken('default rest api name');
const DEFAULT_WS_API_NAME = new InjectionToken('default web socket api name');
function provideDefaultRestApiName(name) {
  return makeEnvironmentProviders([{
    provide: DEFAULT_REST_API_NAME,
    useValue: name
  }]);
}
function provideDefaultWebSocketApiName(name) {
  return makeEnvironmentProviders([{
    provide: DEFAULT_WS_API_NAME,
    useValue: name
  }]);
}
class RestService {
  configService;
  creds;
  idToken;
  apiNameSubject;
  apiName$;
  apiName;
  axios;
  configSubject = new BehaviorSubject(undefined);
  config$ = this.configSubject.asObservable();
  config = toSignal(this.config$, {
    initialValue: this.configSubject.getValue()
  });
  logger;
  sub;
  constructor(configService, creds, idToken) {
    this.configService = configService;
    this.creds = creds;
    this.idToken = idToken;
    this.logger = inject(LoggerService).child({
      service: 'RestService'
    });
    const defaultApiName = inject(DEFAULT_REST_API_NAME);
    this.apiNameSubject = new BehaviorSubject(defaultApiName);
    this.apiName$ = this.apiNameSubject.asObservable();
    this.apiName = toSignal(this.apiName$, {
      initialValue: this.apiNameSubject.getValue()
    });
    this.axios = axios.create();
    this.sub = this.configService.config$.pipe(map(config => config?.API)).pipe(filter(config => config?.REST ? true : false)).subscribe(config => {
      this.onConfig(config);
    });
  }
  createUrl(path, query) {
    const config = this.getApiConfig();
    const endpoint = config.endpoint;
    let url = `${endpoint}${path}`;
    if (query) {
      url += `?${stringify(query, {
        indices: false
      })}`;
    }
    return url;
  }
  del(path, data, query, headers = {}) {
    return this.sendRequest({
      data,
      headers,
      method: HttpMethodMap.DELETE,
      url: this.createUrl(path, query)
    });
  }
  get(path, query, headers = {}) {
    return this.sendRequest({
      headers,
      method: HttpMethodMap.GET,
      url: this.createUrl(path, query)
    });
  }
  getApiConfig() {
    const apiName = this.getApiName();
    const config = this.getConfig();
    if (config) {
      const api = config.REST[apiName];
      if (api) {
        return api;
      }
    }
    throw new Error(`no api config for: ${apiName}`);
  }
  getApiName() {
    return this.apiName();
  }
  getConfig() {
    return this.config();
  }
  init() {
    this.logger.debug({}, 'init');
  }
  onConfig(config) {
    this.logger.debug({
      config
    }, 'onConfig');
    this.configSubject.next(config);
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  patch(path, data, query, headers = {}) {
    return this.sendRequest({
      data,
      headers,
      method: HttpMethodMap.PATCH,
      url: this.createUrl(path, query)
    });
  }
  post(path, data, query, headers = {}) {
    return this.sendRequest({
      data,
      headers,
      method: HttpMethodMap.POST,
      url: this.createUrl(path, query)
    });
  }
  put(path, data, query, headers = {}) {
    return this.sendRequest({
      data,
      headers,
      method: HttpMethodMap.PUT,
      url: this.createUrl(path, query)
    });
  }
  sendRequest(request) {
    this.logger.debug({
      request
    }, 'sendRequest');
    const original = JSON.parse(JSON.stringify(request));
    const final = this.signRequest(request);
    return this.axios.request(final).catch(e => {
      if (e.isAxiosError) {
        const errorType = e.response.headers.get('X-Amzn-ErrorType');
        this.logger.debug({
          errorType,
          e
        }, 'isAxiosError');
        switch (errorType) {
          case 'ExpiredTokenException':
            this.logger.debug({}, 'refreshing credentials');
            return this.creds.refresh().then(() => {
              return this.sendRequest(original);
            });
          default:
            throw convertAxiosErrorToError(e);
        }
      }
      throw e;
    });
  }
  setApiName(name) {
    this.apiNameSubject.next(name);
  }
  signRequest(request) {
    const headers = request.headers || {};
    const config = this.getApiConfig();
    const credentials = this.creds.credentials();
    const idToken = this.idToken.token();
    if (idToken) {
      headers[HttpHeadersMap.X_AUTHED_USER] = idToken.toString();
    }
    let data = request.data;
    if (data && typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    if (credentials) {
      const signed = Signer.sign({
        data,
        headers,
        method: request.method,
        url: request.url
      }, {
        access_key: credentials?.accessKeyId,
        secret_key: credentials?.secretAccessKey,
        session_token: credentials?.sessionToken
      }, {
        region: config.region,
        service: config.service || 'execute-api'
      });
      if (signed.headers) {
        delete signed.headers['host'];
        delete signed.headers['Host'];
      }
      return {
        data: request.data,
        headers: signed.headers,
        method: signed.method,
        url: signed.url
      };
    } else {
      return request;
    }
  }
  static ɵfac = function RestService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RestService)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.CredentialsService), i0.ɵɵinject(i2.IdTokenService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RestService,
    factory: RestService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RestService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ConfigService
  }, {
    type: i2.CredentialsService
  }, {
    type: i2.IdTokenService
  }], null);
})();
class WebSocketService {
  configService;
  creds;
  idToken;
  apiNameSubject;
  apiName$;
  apiName;
  configSubject = new BehaviorSubject({});
  config$ = this.configSubject.asObservable();
  config = toSignal(this.config$, {
    initialValue: this.configSubject.getValue()
  });
  connectedSubject = new BehaviorSubject(false);
  connected$ = this.connectedSubject.asObservable();
  connected = toSignal(this.connected$, {
    initialValue: this.connectedSubject.getValue()
  });
  connectingPromise;
  logger;
  receivedSubject = new Subject();
  received$ = this.receivedSubject.asObservable();
  socket;
  sub;
  timeoutSubject = new BehaviorSubject(minutesToMilliseconds(3));
  timeout$ = this.timeoutSubject.asObservable();
  timeout = toSignal(this.timeout$, {
    initialValue: this.timeoutSubject.getValue()
  });
  constructor(configService, creds, idToken) {
    this.configService = configService;
    this.creds = creds;
    this.idToken = idToken;
    this.logger = inject(LoggerService).child({
      service: 'Amplify.WebsocketService'
    });
    const defaultApiName = inject(DEFAULT_WS_API_NAME);
    this.apiNameSubject = new BehaviorSubject(defaultApiName);
    this.apiName$ = this.apiNameSubject.asObservable();
    this.apiName = toSignal(this.apiName$, {
      initialValue: this.apiNameSubject.getValue()
    });
    this.sub = this.configService.config$.pipe(map(config => config?.API.WS)).pipe(filter(config => config ? true : false)).subscribe(config => {
      this.onConfig(config);
    });
  }
  connect() {
    if (!this.connectingPromise) {
      this.connectingPromise = this.creds.waitFor().then(() => {
        const credentials = this.creds.credentials();
        const endpoint = this.getEndpoint();
        const signingRegion = this.getRegion();
        const signingService = this.getSigningService();
        const idToken = this.idToken.token();
        const query = {};
        if (idToken) {
          query[COGNITO_IDENTITY_TOKEN_QUERY_PARAM] = idToken?.toString();
        }
        const signed = presignUrl({
          method: HttpMethodMap.GET,
          url: new URL(`${endpoint}?${stringify(query)}`)
        }, {
          credentials,
          signingService,
          signingRegion
        });
        return new Promise((resolve, reject) => {
          this.socket = new WebSocket(signed);
          this.socket.addEventListener('close', event => {
            this.logger.debug({
              event
            }, 'received close event');
            this.onClose(event, reject);
          });
          this.socket.addEventListener('error', event => {
            this.logger.debug({
              event
            }, 'recieved error event');
            this.onError(event, reject);
          });
          this.socket.addEventListener('message', event => {
            this.logger.debug({
              event
            }, 'received message event');
            this.onMessage(event);
          });
          this.socket.addEventListener('open', event => {
            this.logger.debug({
              event
            }, 'received open event');
            this.onOpen(event, resolve);
          });
        });
      }).catch(e => {
        this.logger.error(e, 'connect');
        this.connectingPromise = undefined;
        return false;
      });
    }
    return this.connectingPromise;
  }
  getApiConfig() {
    const apiName = this.getApiName();
    const config = this.config();
    if (config) {
      const api = config[apiName];
      if (api) {
        return api;
      }
    }
    throw new Error(`no api config for: ${apiName}`);
  }
  getApiName() {
    return this.apiName();
  }
  getEndpoint() {
    const config = this.getApiConfig();
    return config.endpoint;
  }
  getRegion() {
    const config = this.getApiConfig();
    return config.region;
  }
  getSigningService() {
    const config = this.getApiConfig();
    return config.service || 'execute-api';
  }
  init() {
    this.logger.debug({}, 'init');
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose(event, reject) {
    reject(event.reason);
    this.connectingPromise = undefined;
  }
  onConfig(config) {
    this.configSubject.next(config);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError(event, reject) {
    reject(event);
    this.connectingPromise = undefined;
  }
  onMessage(event) {
    const message = JSON.parse(event.data);
    this.logger.debug({
      message
    }, 'onMessage');
    this.receivedSubject.next(message);
  }
  onOpen(event, resolve) {
    resolve(true);
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  request(message) {
    return this.waitUntilConnected().then(() => {
      const response = this.received$.pipe(filter(incoming => {
        return incoming.action_id === message.action_id;
      })).pipe(timeout({
        first: this.timeout()
      }));
      return this.sendMessage(message).then(() => {
        return firstValueFrom(response);
      });
    });
  }
  sendMessage(message) {
    this.logger.debug({
      message
    }, 'sendMessage');
    return this.waitUntilConnected().then(() => {
      this.socket.send(JSON.stringify(message));
      return message;
    });
  }
  waitUntilConnected() {
    this.connect();
    return firstValueFrom(this.connected$.pipe(filter(connected => connected === true)));
  }
  static ɵfac = function WebSocketService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WebSocketService)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.CredentialsService), i0.ɵɵinject(i2.IdTokenService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WebSocketService,
    factory: WebSocketService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WebSocketService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ConfigService
  }, {
    type: i2.CredentialsService
  }, {
    type: i2.IdTokenService
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_REST_API_NAME, DEFAULT_WS_API_NAME, RestService, WebSocketService, provideDefaultRestApiName, provideDefaultWebSocketApiName };
