import * as i0 from '@angular/core';
import { Injectable, inject, computed, InjectionToken, makeEnvironmentProviders, NgModule } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as i1 from 'angular-auth-oidc-client';
import { AuthModule, provideAuth, StsConfigLoader, LogLevel, StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { startWith, map, firstValueFrom, BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import * as Bluebird from 'bluebird';
import { NG_CONFIG_DEFAULT_REGION } from '@digitaltoolbuilders/ng-config';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { CommonModule } from '@angular/common';
class AccessTokenService {
  oidc;
  payload;
  payload$;
  token;
  token$;
  constructor(oidc) {
    this.oidc = oidc;
    this.payload$ = this.oidc.getPayloadFromAccessToken().pipe(startWith(undefined)).pipe(map(payload => payload));
    this.payload = toSignal(this.payload$, {
      initialValue: undefined
    });
    this.token$ = this.oidc.getAccessToken().pipe(startWith(undefined));
    this.token = toSignal(this.token$, {
      initialValue: undefined
    });
  }
  static ɵfac = function AccessTokenService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AccessTokenService)(i0.ɵɵinject(i1.OidcSecurityService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AccessTokenService,
    factory: AccessTokenService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccessTokenService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OidcSecurityService
  }], null);
})();
class AuthService {
  oidc;
  authed;
  authed$;
  isAuthed;
  logger;
  constructor(oidc) {
    this.oidc = oidc;
    this.logger = inject(LoggerService).child({
      service: 'NgAuthService'
    });
    this.authed$ = this.oidc.getUserData().pipe(startWith(undefined)).pipe(map(result => {
      return result?.userData;
    }));
    this.authed = toSignal(this.authed$, {
      initialValue: undefined
    });
    this.isAuthed = computed(() => {
      const result = this.oidc.authenticated();
      return result.isAuthenticated;
    });
  }
  checkAuth() {
    return this.oidc.checkAuth();
  }
  signIn() {
    return Bluebird.try(() => {
      this.oidc.authorize();
    });
  }
  signOut() {
    return firstValueFrom(this.oidc.logoff());
  }
  static ɵfac = function AuthService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(i1.OidcSecurityService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthService,
    factory: AuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OidcSecurityService
  }], null);
})();
class IdTokenService {
  oidc;
  payload;
  payload$;
  token;
  token$;
  constructor(oidc) {
    this.oidc = oidc;
    this.payload$ = this.oidc.getPayloadFromIdToken().pipe(startWith(undefined)).pipe(map(payload => payload));
    this.payload = toSignal(this.payload$, {
      initialValue: undefined
    });
    this.token$ = this.oidc.getIdToken().pipe(startWith(undefined));
    this.token = toSignal(this.token$, {
      initialValue: undefined
    });
  }
  static ɵfac = function IdTokenService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IdTokenService)(i0.ɵɵinject(i1.OidcSecurityService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IdTokenService,
    factory: IdTokenService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdTokenService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OidcSecurityService
  }], null);
})();
const NG_AUTH_CONFIG = new InjectionToken('observable for auth config');
function provideNgAuthConfig(factoryFn) {
  return makeEnvironmentProviders([{
    provide: NG_AUTH_CONFIG,
    useFactory: factoryFn
  }]);
}
function isCreds(creds) {
  return creds ? true : false;
}
class CredentialsService {
  idToken;
  credentialsSubject = new BehaviorSubject(undefined);
  credentials$ = this.credentialsSubject.asObservable();
  credentials = toSignal(this.credentials$, {
    initialValue: this.credentialsSubject.getValue()
  });
  identityPoolId;
  logger;
  region;
  sub;
  userPoolId;
  constructor(idToken) {
    this.idToken = idToken;
    this.logger = inject(LoggerService).child({
      service: 'CredentialsService'
    });
    const configSignal = toSignal(inject(NG_AUTH_CONFIG));
    const defaultRegion = inject(NG_CONFIG_DEFAULT_REGION);
    this.identityPoolId = computed(() => {
      const config = configSignal();
      return config?.identityPoolId || '';
    });
    this.region = computed(() => {
      const config = configSignal();
      return config?.region || defaultRegion;
    });
    this.userPoolId = computed(() => {
      const config = configSignal();
      return config?.userPoolId || '';
    });
    this.sub = this.idToken.token$.pipe(distinctUntilChanged()).subscribe(token => {
      this.onIdTokenChange(token);
    });
  }
  createCognitoIdpProviderKey() {
    const region = this.region();
    const userPoolId = this.userPoolId();
    return `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
  }
  getCredentials(token) {
    const identityPoolId = this.identityPoolId();
    const logins = {};
    const region = this.region();
    if (token) {
      const providerKey = this.createCognitoIdpProviderKey();
      logins[providerKey] = token;
    }
    return fromCognitoIdentityPool({
      identityPoolId,
      logins,
      clientConfig: {
        region
      }
    })().then(credentials => {
      this.logger.debug({
        credentials
      }, 'onIdTokenChange');
      this.credentialsSubject.next(credentials);
      return credentials;
    });
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  onIdTokenChange(token) {
    this.getCredentials(token).catch(e => {
      this.logger.error(e);
      throw e;
    });
  }
  refresh() {
    const token = this.idToken.token();
    return this.getCredentials(token).catch(e => {
      this.logger.error(e);
      throw e;
    });
  }
  waitFor() {
    const creds = this.credentials$.pipe(filter(isCreds));
    return firstValueFrom(creds);
  }
  static ɵfac = function CredentialsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CredentialsService)(i0.ɵɵinject(IdTokenService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CredentialsService,
    factory: CredentialsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CredentialsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: IdTokenService
  }], null);
})();
class NgAuthModule {
  static ɵfac = function NgAuthModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgAuthModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgAuthModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [provideAuth({
      loader: {
        provide: StsConfigLoader,
        deps: [NG_AUTH_CONFIG],
        useFactory: () => {
          const logger = inject(LoggerService);
          const config = inject(NG_AUTH_CONFIG).pipe(map(config => {
            const openid = {
              authority: `https://cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`,
              checkRedirectUrlWhenCheckingIfIsCallback: false,
              clientId: config.userPoolClientId,
              customParamsEndSessionRequest: {
                client_id: config.userPoolClientId,
                logout_uri: config.signOutUrl
              },
              redirectUrl: config.signInUrl,
              responseType: 'code',
              scope: config.scopes.join(' '),
              // silentRenew: true,
              useRefreshToken: true,
              logLevel: LogLevel.Debug
            };
            logger.debug({
              openid
            });
            return openid;
          }));
          return new StsConfigHttpLoader(config);
        }
      }
    })],
    imports: [CommonModule, AuthModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgAuthModule, [{
    type: NgModule,
    args: [{
      exports: [AuthModule],
      imports: [CommonModule],
      providers: [provideAuth({
        loader: {
          provide: StsConfigLoader,
          deps: [NG_AUTH_CONFIG],
          useFactory: () => {
            const logger = inject(LoggerService);
            const config = inject(NG_AUTH_CONFIG).pipe(map(config => {
              const openid = {
                authority: `https://cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`,
                checkRedirectUrlWhenCheckingIfIsCallback: false,
                clientId: config.userPoolClientId,
                customParamsEndSessionRequest: {
                  client_id: config.userPoolClientId,
                  logout_uri: config.signOutUrl
                },
                redirectUrl: config.signInUrl,
                responseType: 'code',
                scope: config.scopes.join(' '),
                // silentRenew: true,
                useRefreshToken: true,
                logLevel: LogLevel.Debug
              };
              logger.debug({
                openid
              });
              return openid;
            }));
            return new StsConfigHttpLoader(config);
          }
        }
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AccessTokenService, AuthService, CredentialsService, IdTokenService, NgAuthModule, provideNgAuthConfig };
