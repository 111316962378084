"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parent = exports.logger = exports.LOG_LEVEL = void 0;
const pino_1 = require("pino");
exports.LOG_LEVEL = process.env['PINO_LOG_LEVEL'] || 'info';
exports.logger = (0, pino_1.pino)({
  level: exports.LOG_LEVEL
});
exports.parent = exports.logger;
