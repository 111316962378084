import { Injectable } from '@angular/core';
import { UserServiceAdapter } from './user.service.adapter';
import { User, UserGetAction, UserInterface, UserViewedEvent } from '@wam/models';
import { WebSocketService } from '@digitaltoolbuilders/ng-api';

@Injectable({
  providedIn: 'root'
})
export class WebsocketServiceAdapter implements UserServiceAdapter {

  constructor(private socket: WebSocketService) { }

  get(action: UserGetAction): Promise<UserViewedEvent> {
    
    return this.socket.request<UserInterface>(action)
    .then((message) => {

      return new UserViewedEvent({
        ...message,
        payload: new User(message.payload),
      });

    });

  }

}
