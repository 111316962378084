"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.phone_number = exports.legal_name = exports.date_of_birth = void 0;
const Joi = require("joi");
const defaults_1 = require("./defaults");
exports.date_of_birth = Joi.string().max(10);
exports.legal_name = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
exports.phone_number = Joi.string().max(44).min(5);
