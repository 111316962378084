import { Route } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';

export const appRoutes: Route[] = [
  {
    component: HomepageComponent,
    path: '',
    pathMatch: 'full',
  },
  {
    component: SignInComponent,
    path: 'sign-in',
  },
  {
    component: SignOutComponent,
    path: 'sign-out',
  },
];
