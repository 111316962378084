import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import { Logger } from 'pino';
import { AuthedIdentity } from '@wam/iso';
import { AuthService, CredentialsService } from '@digitaltoolbuilders/ng-auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass',
})
export class AppComponent implements OnInit {
  
  authed: Signal<AuthedIdentity | undefined>;

  isAuthed: Signal<boolean>;

  private logger: Logger;

  title = 'marketing-tool';

  constructor(
    private auth: AuthService<AuthedIdentity>,
    private creds: CredentialsService,
    parent: LoggerService,
  ) { 

    this.logger = parent.child({
      component: 'AppComponent',
    });

    this.authed = this.auth.authed;

    this.isAuthed = this.auth.isAuthed;

    // debugger;

  }

  ngOnInit(): void {
    
    this.logger.debug({}, 'ngOnInit');

    this.creds.credentials$.subscribe((creds) => this.logger.debug({ creds }, 'credentials'));

    this.auth.checkAuth()
    .subscribe(({ accessToken, errorMessage, idToken, isAuthenticated, userData }) => {

      this.logger.debug({ accessToken, errorMessage, idToken, isAuthenticated, userData }, 'checkAuth');

    });

    // const config$ = this.config.config$
    // .pipe(filter((config): config is AppRegionalConfig => (config) ? true : false));

    // combineLatest({
    //   token: this.oidcService.getIdToken(),
    //   tokenPayload: this.oidcService.getPayloadFromIdToken(),
    //   identityPoolId: config$.pipe(map((config) => {

    //     return config.amplify.Auth?.Cognito.identityPoolId as string;

    //   })),
    //   region: this.config.region$,
    //   userPoolId: config$.pipe(map((config) => config.amplify.Auth?.Cognito.userPoolId as string)),
    // })
    // .subscribe(({ identityPoolId, region, token, tokenPayload, userPoolId }) => {

    //   this.logger.debug({ identityPoolId, region, token, tokenPayload }, 'before credentials');

    //   const logins: Record<string,string> = {};

    //   if (token && token !== '') {

    //     logins[`cognito-idp.${region}.amazonaws.com/${userPoolId}`] = token;

    //   }

    //   fromCognitoIdentityPool({
    //     identityPoolId,
    //     logins,
    //     clientConfig: {
    //       region,
    //     },
    //   })()
    //   .then((creds) => {

    //     this.logger.debug({ creds }, 'fromCognitoIdentityPool');

    //   });

    // });

  }

  signIn() {

    this.auth.signIn();

  }

  signOut() {

    this.auth.signOut();

    // combineLatest({
    //   client_id: this.config.getOpenIDConfiguration().pipe(map((openid) => {

    //     return openid?.clientId as string;

    //   })),
    //   url: this.config.getOpenIDConfiguration().pipe(map((openid) => {

    //     return `${openid?.authWellknownEndpoints?.endSessionEndpoint}`;
  
    //   })),
    //   token: this.oidcService.getIdToken(),
    // })
    // .subscribe(({ client_id, url, token }) => {



    // });

  }

}
