import { ConsistentReadInterface, ExclusiveStartKeyInterface, LimitInterface, ModelActionAbstract } from "@digitaltoolbuilders/iso";
import { USER_SESSION } from "../../const";
import { UserSession } from "./model";

export const UserSessionActionNameMap = {
  CLOSE: `${USER_SESSION}/close`,
  GET: `${USER_SESSION}/get`,
  LIST: `${USER_SESSION}/list`,
  OPEN: `${USER_SESSION}/open`,
  TERMINATE: `${USER_SESSION}/terminate`,
} as const;

export class UserSessionCloseAction extends ModelActionAbstract<UserSessionClosePayload> {

  readonly action_name = UserSessionActionNameMap.CLOSE;

  readonly model_name = USER_SESSION;

}

export type UserSessionClosePayload = Pick<UserSession, 'closed_epoch' | 'last_updated_epoch' | 'session_id'>;

export class UserSessionGetAction extends ModelActionAbstract<UserSessionGetPayload> {

  readonly action_name = UserSessionActionNameMap.GET;

  readonly model_name = USER_SESSION;

}

export type UserSessionGetPayload = Pick<UserSession, 'session_id'>
  & ConsistentReadInterface;

export class UserSessionListAction extends ModelActionAbstract<UserSessionListPayload> {

  readonly action_name = UserSessionActionNameMap.LIST;

  readonly model_name = USER_SESSION;

}

export type UserSessionListPayload = Partial<Pick<UserSession, 'status' | 'type' | 'user_id'>> 
  & ExclusiveStartKeyInterface
  & LimitInterface;

export class UserSessionOpenAction extends ModelActionAbstract<UserSessionOpenPayload> {

  readonly action_name = UserSessionActionNameMap.OPEN;

  readonly model_name = USER_SESSION;

}

export type UserSessionOpenPayload = Omit<UserSession, 
  | 'created_epoch' 
  | 'last_updated_epoch' 
  | 'model_id' 
  | 'status'>;
