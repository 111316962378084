import * as i0 from '@angular/core';
import { InjectionToken, makeEnvironmentProviders, provideAppInitializer, inject, Injectable, Inject, NgModule } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as i1 from '@digitaltoolbuilders/ng-logging';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
const NG_CONFIG_DEFAULT_REGION = new InjectionToken('NG_CONFIG_DEFAULT_REGION');
function provideDefaultConfigRegion(region) {
  return makeEnvironmentProviders([{
    provide: NG_CONFIG_DEFAULT_REGION,
    useValue: region
  }]);
}
function provideConfigInitializer(interceptorFn) {
  return provideAppInitializer(() => {
    const service = inject(ConfigService);
    return service.init(interceptorFn);
  });
}
function defaultInterceptor(config) {
  return config;
}
class ConfigService {
  defaultRegion;
  availableRegionsSubject = new BehaviorSubject([]);
  availableRegions$ = this.availableRegionsSubject.asObservable();
  availableRegions = toSignal(this.availableRegions$, {
    initialValue: this.availableRegionsSubject.getValue()
  });
  configMap;
  configSubject = new BehaviorSubject(undefined);
  config$ = this.configSubject.asObservable();
  config = toSignal(this.config$, {
    initialValue: this.configSubject.getValue()
  });
  logger;
  interceptorFn = config => config;
  regionSubject;
  region$;
  region;
  constructor(defaultRegion, parent) {
    this.defaultRegion = defaultRegion;
    this.logger = parent.child({
      service: 'ConfigService'
    });
    this.regionSubject = new BehaviorSubject(this.defaultRegion);
    this.region$ = this.regionSubject.asObservable();
    this.region = toSignal(this.region$, {
      initialValue: this.regionSubject.getValue()
    });
  }
  init(interceptorFn) {
    this.interceptorFn = interceptorFn || defaultInterceptor;
    return this.loadConfigs().catch(e => {
      this.logger.error(e);
    });
  }
  loadConfigs() {
    const url = `assets/app-config-map.json`;
    this.logger.debug({
      url
    }, 'loadConfigs');
    return axios.request({
      method: 'GET',
      responseType: 'json',
      url
    }).then(response => {
      const {
        regions
      } = response.data;
      this.logger.debug({
        data: response.data
      }, 'loadConfigs');
      this.availableRegionsSubject.next(regions);
      this.configMap = this.interceptorFn(response.data);
      const config = this.configMap.regionalConfigs[this.defaultRegion];
      this.logger.debug({
        config
      }, 'loadConfigs');
      if (config) {
        // console.log('NgConfig', 'setting config', this.defaultRegion)
        this.configSubject.next(config);
      } else {
        // console.log('NgConfig', 'no config for region', this.defaultRegion);
        this.configSubject.next(undefined);
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch(exception => {
      let failureReason = exception.message;
      if (exception.isAxiosError) {
        failureReason = exception.response.statusText;
      }
      this.logger.debug({
        failureReason
      }, 'loadConfigs');
      throw exception;
    });
  }
  selectRegion(region) {
    this.logger.debug({
      region
    }, 'selectRegion');
    this.regionSubject.next(region);
    const config = this.configMap.regionalConfigs[region];
    this.configSubject.next(config);
  }
  static ɵfac = function ConfigService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ConfigService)(i0.ɵɵinject(NG_CONFIG_DEFAULT_REGION), i0.ɵɵinject(i1.LoggerService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ConfigService,
    factory: ConfigService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_CONFIG_DEFAULT_REGION]
    }]
  }, {
    type: i1.LoggerService
  }], null);
})();
class NgConfigModule {
  static ɵfac = function NgConfigModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgConfigModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgConfigModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [ConfigService, provideDefaultConfigRegion('us-west-2'), provideConfigInitializer()],
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgConfigModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [ConfigService, provideDefaultConfigRegion('us-west-2'), provideConfigInitializer()]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ConfigService, NG_CONFIG_DEFAULT_REGION, NgConfigModule, provideConfigInitializer, provideDefaultConfigRegion };
