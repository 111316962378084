import { ModelEventAbstract, ModelList } from "@digitaltoolbuilders/iso";
import { USER_SESSION } from "../../const";
import { UserSession } from "./model";

export class UserSessionClosedEvent extends ModelEventAbstract<UserSession> {

  readonly event_name = UserSessionEventNameMap.CLOSED;

  readonly model_name = USER_SESSION;

}

export const UserSessionEventNameMap = {
  CLOSED: `${USER_SESSION}:closed`,
  LISTED: `${USER_SESSION}:listed`,
  OPENED: `${USER_SESSION}:opened`,
  TERMINATED: `${USER_SESSION}:terminated`,
  VIEWED: `${USER_SESSION}:viewed`,
} as const;

export class UserSessionListedEvent extends ModelEventAbstract<ModelList<UserSession>> {

  readonly event_name = UserSessionEventNameMap.LISTED;

  readonly model_name = USER_SESSION;

}

export class UserSessionOpenedEvent extends ModelEventAbstract<UserSession> {

  readonly event_name = UserSessionEventNameMap.OPENED;

  readonly model_name = USER_SESSION;

}

export class UserSessionViewedEvent extends ModelEventAbstract<UserSession> {

  readonly event_name = UserSessionEventNameMap.VIEWED;

  readonly model_name = USER_SESSION;

}
