
import Joi from 'joi';
import { UserCreatePayload, UserFindPayload, UserGetPayload, UserUpdatePayload, UserRole, UserRoles, UserListPayload, UserStatuses, UserActionNameMap as ActionNameMap, UserSignUpPayload, UserChangeRolesPayload, UserCreateAction, UserFindAction, UserGetAction, UserListAction, UserSignUpAction, UserUpdateAction, UserChangeRolesAction } from '@wam/models';
import { consistent_read, createModelActionSchema, epoch, exclusive_start_key, limit, phone_number, user_id } from '@digitaltoolbuilders/schema-helpers';

export const birthdate = Joi.string().pattern(/\d{2}\/\d{2}\/\d{4}/);

export const email = Joi.string().email({
  tlds: false,
});

export const email_verified = Joi.boolean().default(false);

export const family_name = Joi.string().max(100);

export const given_name = Joi.string().max(100);

export const local_time_zone = Joi.string().max(100);

export const name = Joi.string().max(200);

export const phone_number_verified = Joi.boolean().default(false);

export const picture = Joi.string().uri({
  domain: {
    tlds: false,
  },
});

export const role = Joi.string().allow(...UserRoles);

export const roles = Joi.array<Array<UserRole>>()
  .items(role);

export const status = Joi.string().allow(...UserStatuses);

export const changeRolesPayload = Joi.object<UserChangeRolesPayload>()
  .keys({
    last_updated_epoch: epoch.required(),
    roles: roles.required(),
    user_id: user_id.required(),
  });

export const changeRolesAction = createModelActionSchema<UserChangeRolesAction, UserChangeRolesPayload>(ActionNameMap.CHANGE_ROLES, changeRolesPayload);

export const createPayload = Joi.object<UserCreatePayload>()
  .keys({
    birthdate: birthdate.required(),
    email: email.optional().allow(null),
    email_verified: email_verified.default(false),
    family_name: family_name.optional().allow(null),
    given_name: given_name.optional().allow(null),
    local_time_zone: local_time_zone.optional().allow(null),
    name: name.required(),
    phone_number: phone_number.required(),
    phone_number_verified: phone_number_verified.default(false),
    picture: picture.optional().allow(null),
    roles: roles.optional().allow(null),
    status: status.optional().allow(null),
    user_id: user_id.required(),
  });

export const createAction = createModelActionSchema<UserCreateAction, UserCreatePayload>(ActionNameMap.CREATE, createPayload);

export const findPayload = Joi.object<UserFindPayload>()
  .keys({
    email: email.optional().allow(null),
    phone_number: email.optional().allow(null),
  })
  .xor('email', 'phone_number');

export const findAction = createModelActionSchema<UserFindAction, UserFindPayload>(ActionNameMap.FIND, findPayload);

export const getPayload = Joi.object<UserGetPayload>()
  .keys({
    ConsistentRead: consistent_read,
    user_id: user_id.required(),
  });

export const getAction = createModelActionSchema<UserGetAction, UserGetPayload>(ActionNameMap.GET, getPayload);

export const listPayload = Joi.object<UserListPayload>()
  .keys({
    ExclusiveStartKey: exclusive_start_key,
    Limit: limit,
    phone_number: phone_number.optional().allow(null),
    status: status.optional().allow(null),
  });

export const listAction = createModelActionSchema<UserListAction, UserListPayload>(ActionNameMap.LIST, listPayload);

export const signUpPayload = Joi.object<UserSignUpPayload>()
  .keys({
    birthdate: birthdate.required(),
    local_time_zone: local_time_zone.required(),
    name: name.required(),
    phone_number: phone_number.required(),
    user_id: user_id.required(),
  });

export const signUpAction = createModelActionSchema<UserSignUpAction, UserSignUpPayload>(ActionNameMap.SIGN_UP, signUpPayload);

export const updatePayload = Joi.object<UserUpdatePayload>()
  .keys({
    family_name: family_name.optional().allow(null),
    given_name: given_name.optional().allow(null),
    last_updated_epoch: epoch.required(),
    local_time_zone: local_time_zone.required(),
    name: name.required(),
    picture: picture.optional().allow(null),
    user_id: user_id.required(),
  });

export const updateAction = createModelActionSchema<UserUpdateAction, UserUpdatePayload>(ActionNameMap.UPDATE, updatePayload);