<div class="flex flex-col">

  <div class="flex flex-col">

    <ng-wood-disc-card-summary
      *ngFor="let disc of discs()"
      [actions]="actions()"
      [disc]="disc"></ng-wood-disc-card-summary>

    <div class="flex flex-col"
      *ngIf="empty()">

      <mat-card>

        <mat-card-header>

          <mat-card-content>

            <span>No Discs</span>

          </mat-card-content>

        </mat-card-header>

      </mat-card>

    </div>

  </div>

</div>
