<div>

  <mat-card
    *ngIf="disc() as d">

    <mat-card-header>

      <mat-card-title>Wood Disc #{{d.disc_id}}</mat-card-title>
      <mat-card-subtitle>{{d.status}} : {{d.shape}} {{d.species}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>



    </mat-card-content>

    <mat-card-actions
      *ngIf="actions() as act">

      <ng-container *ngTemplateOutlet="act; context: { disc: d }"></ng-container>

    </mat-card-actions>

  </mat-card>

</div>
