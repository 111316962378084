import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewAvailableComponent } from './view-available/view-available.component';
import { RouterModule } from '@angular/router';
import { routes } from './disc.routes';
import { WoodDiscModule } from '@wam/ng-models';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ViewAvailableComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    WoodDiscModule,
    RouterModule.forChild(routes),
  ],
})
export class DiscModule {}
