
export const USER = 'user';

export const USER_COGNITO = `${USER}.cognito`;

export const USER_SESSION = `${USER}.session`;

export const WOOD_DISC = 'wood-disc';

export const WOOD_DISC_PICTURE = `${WOOD_DISC}.picture`;
