import { WoodDiscActionNameMap as ActionMap, WoodDiscCreateAction, WoodDiscCreatePayload, WoodDiscDimensions, WoodDiscDimensionsThickness, WoodDiscFeatures, WoodDiscGetAction, WoodDiscGetPayload, WoodDiscListAction, WoodDiscListPayload, WoodDiscShapes, WoodDiscStatuses, WoodDiscUpdateAction, WoodDiscUpdatePayload, WoodSpecies } from "@wam/models";
import Joi from "joi";
import { inches, percentage, pounds } from "../measures";
import { consistent_read, createModelActionSchema, epoch, exclusive_start_key, limit } from "@digitaltoolbuilders/schema-helpers";

export const disc_id = Joi.string();

const optionalBoolean = Joi.boolean().default(false).optional().allow(null);

export const features = Joi.object<WoodDiscFeatures>()
  .keys({
    arms: optionalBoolean,
    dark_pith: optionalBoolean,
    holes: optionalBoolean,
    horns: optionalBoolean,
    eyes: optionalBoolean,
  })

export const has_pentacryl = optionalBoolean;

export const shape = Joi.string().allow(...WoodDiscShapes);

export const species = Joi.string().allow(...WoodSpecies);

export const status = Joi.string().allow(...WoodDiscStatuses);

export const thickness = Joi.object<WoodDiscDimensionsThickness>()
  .keys({
    largest_inches: inches.required(),
    smallest_inches: inches.required(),
  });

export const dimensions = Joi.object<WoodDiscDimensions>()
  .keys({
    height_inches: inches.required(),
    thickness: thickness.required(),
    weight_pounds: pounds.required(),
    width_inches: inches.required(),
  });

export const createPayload = Joi.object<WoodDiscCreatePayload>()
  .keys({
    dimensions: dimensions.required(),
    disc_id: disc_id.required(),
    features: features.required(),
    has_pentacryl: has_pentacryl,
    moisture_percentage: percentage.optional().allow(null),
    shape: shape.required(),
    species: species.required(),
    starting: dimensions.required(),
    status: status.required(),
  });

export const createAction = createModelActionSchema<WoodDiscCreateAction, WoodDiscCreatePayload>(ActionMap.CREATE, createPayload);

export const getPayload = Joi.object<WoodDiscGetPayload>()
  .keys({
    ConsistentRead: consistent_read,
    disc_id: disc_id.required(),
  });

export const getAction = createModelActionSchema<WoodDiscGetAction, WoodDiscGetPayload>(ActionMap.GET, getPayload);

export const listPayload = Joi.object<WoodDiscListPayload>()
  .keys({
    ExclusiveStartKey: exclusive_start_key,
    Limit: limit,
    shape: shape.optional().allow(null),
    species: species.optional().allow(null),
    status: status.optional().allow(null),
  });

export const listAction = createModelActionSchema<WoodDiscListAction, WoodDiscListPayload>(ActionMap.LIST, listPayload);

export const updatePayload = Joi.object<WoodDiscUpdatePayload>()
  .keys({
    dimensions: dimensions.required(),
    disc_id: disc_id.required(),
    features: features.required(),
    has_pentacryl: has_pentacryl,
    last_updated_epoch: epoch.required(),
    moisture_percentage: percentage.optional().allow(null),
    shape: shape.required(),
    species: species.required(),
    starting: dimensions.required(),
    status: status.required(),
  });

export const updateAction = createModelActionSchema<WoodDiscUpdateAction, WoodDiscUpdatePayload>(ActionMap.UPDATE, updatePayload);
