"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpMethods = exports.HttpMethodMap = exports.HttpHeadersMap = exports.HttpContentTypes = exports.HttpContentTypeMap = void 0;
exports.HttpContentTypeMap = {
  CSS: 'text/css',
  CSV: 'text/csv',
  JPEG: 'image/jpeg',
  JS: 'text/javascript',
  JSON: 'application/json',
  PDF: 'application/pdf',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  XML: 'application/xml'
};
exports.HttpContentTypes = Object.values(exports.HttpContentTypeMap);
exports.HttpHeadersMap = {
  CONTENT_LENGTH: 'Content-Length',
  CONTENT_TYPE: 'Content-Type',
  X_AUTHED_USER: 'X-Authed-User',
  X_USER_POOL_ID: 'X-User-Pool-Id',
  X_USER_POOL_CLIENT_ID: 'X-User-Pool-Client-Id'
};
exports.HttpMethodMap = {
  DELETE: 'DELETE',
  GET: 'GET',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT'
};
exports.HttpMethods = Object.values(exports.HttpMethodMap);
