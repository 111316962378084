import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  standalone: false,
  styleUrl: './homepage.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent {}
