import { inject, Injectable, OnDestroy } from '@angular/core';
import { UserServiceAdapter } from './user.service.adapter';
import { BACKEND_ADAPTER, BackendAdapterMap } from '../backend-adapter';
import { RestServiceAdapter } from './rest.service.adapter';
import { WebsocketServiceAdapter } from './websocket.service.adapter';
import { UserGetAction, UserGetPayload } from '@wam/models';
import { AuthService } from '@digitaltoolbuilders/ng-auth';
import { Subscription } from 'rxjs';
import { Logger } from 'pino';
import { LoggerService } from '@digitaltoolbuilders/ng-logging';
import { user as schemas } from '@wam/schemas';
import { IdService } from '@digitaltoolbuilders/ng-helpers';
import { AuthedIdentity } from '@wam/iso';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  private adapter: UserServiceAdapter;

  private logger: Logger;

  private sub: Subscription;

  constructor(
    private auth: AuthService<AuthedIdentity>,
    private id: IdService,
  ) { 

    const backendAdapter = inject(BACKEND_ADAPTER);

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestServiceAdapter);
        
        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketServiceAdapter);

        break;
    
      default:

        throw new Error(`unknown backend adapter: ${backendAdapter}`);
    
    }

    this.logger = inject(LoggerService).child({
      service: 'UserService',
    });

  }

  get(data: Partial<UserGetPayload>, now = Date.now()) {

    return schemas.getPayload.validateAsync(data, {})
    .then((clean) => {

      const action = new UserGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        payload: clean,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        return model;

      });

    });

  }
  init() {

    this.sub = this.auth.authed$.subscribe((authed) => {

      this.logger.debug({ authed }, 'authed$');

    });

  }
  
  ngOnDestroy(): void {
    
    this.sub?.unsubscribe();

  }

}
