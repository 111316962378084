import { ChangeDetectionStrategy, Component, effect, input, TemplateRef } from '@angular/core';
import { WoodDisc } from '@wam/models';
import { MatTableDataSource } from '@angular/material/table';
import { AnyTemplate } from '../../../common';

@Component({
  selector: 'ng-wood-disc-table',
  standalone: false,
  templateUrl: './table.component.html',
  styleUrl: './table.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {

  actions = input<TemplateRef<AnyTemplate>>();

  discs = input<Array<WoodDisc>>([]);

  display = input<Array<string>>([
    'disc_id',
    'status',
    'shape',
    'species',
    'actions',
  ]);

  table = new MatTableDataSource(this.discs());

  constructor() {

    effect(() => {

      const discs = this.discs();

      this.table.data = discs;

    });

  }

}
