
import { ModelAbstract, ModelInterface } from "@digitaltoolbuilders/iso";
import { USER_COGNITO } from "../../const";

export class UserCognito extends ModelAbstract implements UserCognitoInterface {

  alias: string;

  last_login_epoch?: number;

  readonly model_name = USER_COGNITO;

  region: string;

  status: UserCognitoStatus;

  user_id: string;

  user_name: string;

  user_pool_id: string;

  constructor(input: Partial<Omit<UserCognito, 'model_name'>>) {

    super(input);

    if (input.alias) this.alias = input.alias;
    if (input.last_login_epoch) this.last_login_epoch = input.last_login_epoch;
    if (input.region) this.region = input.region;
    if (input.status) this.status = input.status;
    if (input.user_id) this.user_id = input.user_id;
    if (input.user_name) this.user_name = input.user_name;
    if (input.user_pool_id) this.user_pool_id = input.user_pool_id;

  }

}

export interface UserCognitoInterface extends ModelInterface {

  alias: string;

  last_login_epoch?: number;

  region: string;

  status: UserCognitoStatus;

  user_id: string;

  user_name: string;

  user_pool_id: string;

}

export const UserCognitoStatusMap = {
  ARCHIVED: 'archived',
  COMPROMISED: 'compromised',
  CONFIRMED: 'confirmed',
  FORCE_CHANGE_PASSWORD: 'force-change-password',
  RESET_REQUIRED: 'reset-required',
  UNCONFIRMED: 'unconfirmed',
  UNKNOWN: 'unknown',
} as const;

export type UserCognitoStatus = typeof UserCognitoStatusMap[keyof typeof UserCognitoStatusMap];

export const UserCognitoStatuses = Object.values(UserCognitoStatusMap);
