"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Rule = exports.NotAllowed = exports.Assertion = exports.ACL = exports.DENY = exports.ALLOW = void 0;
const Bluebird = require("bluebird");
exports.ALLOW = 'allow';
exports.DENY = 'deny';
class ACL {
  constructor() {
    this.inheritance = {};
    this.rules = {};
  }
  allow(role, actions, assert) {
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    if (assert && !Array.isArray(assert)) {
      assert = [assert];
    } else {
      assert = [];
    }
    const rule = new Rule(exports.ALLOW, actions, assert);
    actions.forEach(action => {
      this.rules[role] = this.rules[role] || {};
      this.rules[role][action] = this.rules[role][action] || [];
      this.rules[role][action].push(rule);
    });
  }
  deny(role, actions, assert) {
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    if (assert && !Array.isArray(assert)) {
      assert = [assert];
    } else {
      assert = [];
    }
    const rule = new Rule(exports.DENY, actions, assert);
    actions.forEach(action => {
      this.rules[role] = this.rules[role] || {};
      this.rules[role][action] = this.rules[role][action] || [];
      this.rules[role][action].push(rule);
    });
  }
  getRules(role, action) {
    let rules = [];
    if (this.rules[role]) {
      rules = rules.concat(this.rules[role][action] || []);
    }
    if (this.inheritance[role]) {
      rules = rules.concat(this.getRules(this.inheritance[role], action));
    }
    return rules;
  }
  hasAllow(rules) {
    let has = false;
    rules.forEach(rule => {
      if (rule.type === exports.ALLOW) {
        has = true;
      }
    });
    return has;
  }
  hasDeny(rules) {
    let has = false;
    rules.forEach(rule => {
      if (rule.type === exports.DENY) {
        has = true;
      }
    });
    return has;
  }
  inherit(child, parent) {
    this.inheritance[child] = parent;
  }
  isAllowed(roles, action, user, item, context) {
    // evalute all rules for each role
    // matching rules will return true
    const matches = [];
    const promises = [];
    roles.forEach(role => {
      const rules = this.getRules(role, action);
      const promise = Promise.all(rules.map(rule => {
        return rule.evaluate(role, action, user, item, context).then(match => {
          if (match) {
            matches.push(rule);
          }
          return match;
        });
      }));
      promises.push(promise);
    });
    return Promise.all(promises).then(() => {
      if (this.hasAllow(matches) && !this.hasDeny(matches)) {
        return true;
      } else {
        throw new NotAllowed(`Not Allowed to ${action} with ${JSON.stringify(roles)}`);
      }
    });
  }
  listAllowed(roles, action, user, list, context) {
    const allowed = [];
    const promises = [];
    list.forEach(item => {
      promises.push(this.isAllowed(roles, action, user, item).then(() => {
        allowed.push(item);
      }).catch(exception => {
        if (exception.isNotAllowed) {
          return false;
        } else {
          throw exception;
        }
      }));
    });
    return Promise.all(promises).then(() => {
      return allowed;
    });
  }
}
exports.ACL = ACL;
class Assertion {
  constructor(fn, message) {
    this.fn = fn;
    this.message = message;
  }
  assert(role, action, user, item, context) {
    return Bluebird.try(() => {
      return this.fn(role, action, user, item, context);
    });
  }
}
exports.Assertion = Assertion;
class NotAllowed extends Error {
  constructor(m) {
    super(m);
    this.isNotAllowed = true;
    Object.setPrototypeOf(this, NotAllowed.prototype);
  }
  get name() {
    return this.constructor.name;
  }
}
exports.NotAllowed = NotAllowed;
class Rule {
  constructor(type, actions, assert) {
    this.type = type;
    this.actions = actions;
    this.assert = [];
    if (assert) {
      if (!Array.isArray(assert)) {
        assert = [assert];
      }
      this.assert = assert;
    }
  }
  evaluate(role, action, user, item, context) {
    const promises = this.assert.map(assertion => {
      return assertion.assert(role, action, user, item, context);
    });
    return Promise.all(promises).then(results => {
      if (results.includes(false)) {
        return false;
      } else {
        return true;
      }
    });
  }
}
exports.Rule = Rule;
