import { ConsistentReadInterface, ExclusiveStartKeyInterface, LimitInterface, ModelActionAbstract } from "@digitaltoolbuilders/iso";
import { USER } from "../const";
import { User } from "./model";

export const UserActionNameMap = {
  CHANGE_ROLES: `${USER}/change-roles`,
  CREATE: `${USER}/create`,
  FIND: `${USER}/find`,
  GET: `${USER}/get`,
  LIST: `${USER}/list`,
  REGISTER: `${USER}/register`,
  SIGN_UP: `${USER}/sign-up`,
  UPDATE: `${USER}/update`,
} as const;

export class UserChangeRolesAction extends ModelActionAbstract<UserChangeRolesPayload> {

  readonly action_name = UserActionNameMap.CHANGE_ROLES;

  readonly model_name = USER;

}

export type UserChangeRolesPayload = Pick<User, 'last_updated_epoch' | 'roles' | 'user_id'>;

export class UserCreateAction extends ModelActionAbstract<UserCreatePayload> {

  readonly action_name = UserActionNameMap.CREATE;

  readonly model_name = USER;

}

export type UserCreatePayload = Omit<User, 'deleted_epoch' | 'last_updated_epoch' | 'model_name'>

export class UserGetAction extends ModelActionAbstract<UserGetPayload> {

  readonly action_name = UserActionNameMap.GET;

  readonly model_name = USER;

}

export type UserGetPayload = Pick<User, 'user_id'> & ConsistentReadInterface;

export class UserFindAction extends ModelActionAbstract<UserFindPayload> {

  readonly action_name = UserActionNameMap.FIND;

  readonly model_name = USER;

}

export type UserFindPayload = Pick<User,'email'> | Pick<User,'phone_number'>;

export class UserListAction extends ModelActionAbstract<UserListPayload> {

  readonly action_name = UserActionNameMap.LIST;

  readonly model_name = USER;

}

export type UserListPayload = Partial<Pick<User, 'phone_number' | 'status'>> 
  & ExclusiveStartKeyInterface
  & LimitInterface;

export class UserRegisterAction extends ModelActionAbstract<UserRegisterPayload> {

  readonly action_name = UserActionNameMap.REGISTER;

  readonly model_name = USER;

}

export type UserRegisterPayload = Pick<User, 'name' | 'phone_number'> & Partial<Pick<User, 
  | 'email' 
  | 'family_name' 
  | 'given_name' 
  | 'local_time_zone'
  | 'picture'
>>;

export class UserSignUpAction extends ModelActionAbstract<UserSignUpPayload> {

  readonly action_name = UserActionNameMap.SIGN_UP;

  readonly model_name = USER;

}

export type UserSignUpPayload = Pick<UserCreatePayload, 
  | 'birthdate' 
  | 'email' 
  | 'name' 
  | 'phone_number' 
  | 'picture'
  | 'user_id'> & Pick<User, 'local_time_zone'>;

export class UserUpdateAction extends ModelActionAbstract<UserUpdatePayload> {

  readonly action_name = UserActionNameMap.UPDATE;

  readonly model_name = USER;

}

export type UserUpdatePayload = Pick<User, 
  | 'family_name' 
  | 'given_name' 
  | 'last_updated_epoch' 
  | 'local_time_zone' 
  | 'name' 
  | 'picture' 
  | 'user_id'>;
