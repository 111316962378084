
import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const BackendAdapterMap = {
  REST: 'rest',
  WEBSOCKET: 'websocket',
} as const;

export type BackendAdapter = typeof BackendAdapterMap[keyof typeof BackendAdapterMap];

export const BACKEND_ADAPTER = new InjectionToken<BackendAdapter>('backend adapter for services');

export function provideBackendAdapter(adapter: BackendAdapter) {

  return makeEnvironmentProviders([
    {
      provide: BACKEND_ADAPTER,
      useValue: adapter,
    },
  ]);

}
