"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.user_id = exports.epoch = exports.emailAddress = void 0;
const tslib_1 = require("tslib");
const Joi = tslib_1.__importStar(require("joi"));
const defaults_1 = require("./defaults");
exports.emailAddress = Joi.string().email({
  tlds: false
});
exports.epoch = Joi.number().integer();
exports.user_id = Joi.string().max(defaults_1.DEFAULT_STRING_MAX);
