
import Joi from 'joi';
import { UserSessionStatuses, UserSessionTypes, UserSessionOpenPayload, UserSessionActionNameMap as ActionMap, UserSessionGetPayload, UserSessionListPayload, UserSessionClosePayload, UserSessionGetAction, UserSessionOpenAction, UserSessionListAction, UserSessionCloseAction } from '@wam/models';
import { consistent_read, createModelActionSchema, epoch, exclusive_start_key, limit, user_id } from '@digitaltoolbuilders/schema-helpers';
import { user_name, user_pool_id } from './cognito';

export const session_id = Joi.string();

export const status = Joi.string().allow(...UserSessionStatuses);

export const type = Joi.string().allow(...UserSessionTypes);

export const closePayload = Joi.object<UserSessionClosePayload>()
  .keys({
    closed_epoch: epoch.required(),
    last_updated_epoch: epoch.required(),
    session_id: session_id.required(),
  });

export const closeAction = createModelActionSchema<UserSessionCloseAction, UserSessionClosePayload>(ActionMap.CLOSE, closePayload);

export const getPayload = Joi.object<UserSessionGetPayload>()
  .keys({
    ConsistentRead: consistent_read,
    session_id: session_id.required(),
  });

export const getAction = createModelActionSchema<UserSessionGetAction, UserSessionGetPayload>(ActionMap.GET, getPayload);

export const listPayload = Joi.object<UserSessionListPayload>()
  .keys({
    ExclusiveStartKey: exclusive_start_key,
    Limit: limit,
    status: status.optional().allow(null),
    type: type.optional().allow(null),
    user_id: user_id.optional().allow(null),
  });

export const listAction = createModelActionSchema<UserSessionListAction, UserSessionListPayload>(ActionMap.LIST, listPayload);

export const openPayload = Joi.object<UserSessionOpenPayload>()
  .keys({
    cognito_user_pool_id: user_pool_id.required(),
    cognito_username: user_name.required(),
    opened_epoch: epoch.required(),
    session_id: session_id.required(),
    type: type.required(),
    user_id: user_id.required(),
  });

export const openAction = createModelActionSchema<UserSessionOpenAction, UserSessionOpenPayload>(ActionMap.OPEN, openPayload);