"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrencyCodesMap = exports.Currencies = exports.CurrencyMap = exports.USD = exports.XPF = exports.XOF = exports.XAF = exports.VUV = exports.VND = exports.UGX = exports.TWD = exports.TND = exports.RWF = exports.PYG = exports.OMR = exports.MGA = exports.KWD = exports.KRW = exports.KMF = exports.JOD = exports.JPY = exports.ISK = exports.INR = exports.HUF = exports.GNF = exports.DJF = exports.CLP = exports.CAD = exports.BSD = exports.BRL = exports.BOB = exports.BND = exports.BMD = exports.BIF = exports.BHD = exports.BGN = exports.BDT = exports.BBD = exports.BAM = exports.AZN = exports.AWG = exports.AUD = exports.ARS = exports.ANG = exports.AMD = exports.ALL = exports.AFN = exports.AED = void 0;
exports.AED = 'aed';
exports.AFN = 'afn';
exports.ALL = 'all';
exports.AMD = 'amd';
exports.ANG = 'ang';
exports.ARS = 'ars';
exports.AUD = 'aud';
exports.AWG = 'awg';
exports.AZN = 'azn';
exports.BAM = 'bam';
exports.BBD = 'brd';
exports.BDT = 'bdt';
exports.BGN = 'bgn';
exports.BHD = 'bhd';
exports.BIF = 'bif';
exports.BMD = 'bmd';
exports.BND = 'bnd';
exports.BOB = 'bob';
exports.BRL = 'brl';
exports.BSD = 'bsd';
exports.CAD = 'cad';
exports.CLP = 'clp';
exports.DJF = 'djf';
exports.GNF = 'gnf';
exports.HUF = 'huf';
exports.INR = 'inr';
exports.ISK = 'isk';
exports.JPY = 'jpy';
exports.JOD = 'jod';
exports.KMF = 'kmf';
exports.KRW = 'krw';
exports.KWD = 'kwd';
exports.MGA = 'mga';
exports.OMR = 'omr';
exports.PYG = 'pyg';
exports.RWF = 'rwf';
exports.TND = 'tnd';
exports.TWD = 'twd';
exports.UGX = 'ugx';
exports.VND = 'vnd';
exports.VUV = 'vuv';
exports.XAF = 'xaf';
exports.XOF = 'xof';
exports.XPF = 'xpf';
exports.USD = 'usd';
exports.CurrencyMap = {
  CAD: exports.CAD,
  USD: exports.USD
};
exports.Currencies = Object.values(exports.CurrencyMap);
exports.CurrencyCodesMap = {};
exports.CurrencyCodesMap[exports.CAD] = {
  decimal: 'C&#36;',
  hex: 'C&#x24;',
  iso: exports.CAD
};
exports.CurrencyCodesMap[exports.USD] = {
  decimal: '&#36;',
  hex: '&#x0024;',
  html: '',
  iso: exports.USD
};
