<div class="flex flex-col">

  <mat-form-field>

    <mat-label>Status</mat-label>

    <mat-select>

      <mat-option 
        *ngFor="let option of statuses"
        [value]="option">{{option}}</mat-option>

    </mat-select>

    <mat-error>Please select a status for this disc.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Species</mat-label>

    <mat-select>

      <mat-option 
        *ngFor="let option of species"
        [value]="option">{{option}}</mat-option>

    </mat-select>

    <mat-error>Please select a species for this disc.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Shape</mat-label>

    <mat-select>

      <mat-option 
        *ngFor="let option of species"
        [value]="option">{{option}}</mat-option>

    </mat-select>

    <mat-error>Please select a species for this disc.</mat-error>

  </mat-form-field>

  <fieldset>

    <legend>Dimensions</legend>

    <div class="flex flex-col"
      formGroupName="dimensions">

      <mat-form-field>

        <mat-label>Height</mat-label>

        <input type="number" min="0"
          formControlName="height_inches"
          matInput>

        <mat-hint>The height should be entered as inches.</mat-hint>

        <mat-error>Please enter the disc's height in inches.</mat-error>

      </mat-form-field>

      <mat-form-field>

        <mat-label>Width</mat-label>

        <input type="number" min="0"
          formControlName="width_inches"
          matInput>

        <mat-hint>The width should be entered as inches.</mat-hint>

        <mat-error>Please enter the disc's width in inches.</mat-error>

      </mat-form-field>

      <fieldset>

        <legend>Thickness</legend>

        <div class="flex flex-col"
          formGroupName="thickness">

          <mat-form-field>

            <mat-label>Largest</mat-label>
    
            <input type="number" min="0"
              formControlName="largest_inches"
              matInput>
    
            <mat-hint>The thickness should be entered as inches.</mat-hint>
    
            <mat-error>Please enter the disc's largest thickness.</mat-error>
    
          </mat-form-field>
    
          <mat-form-field>
    
            <mat-label>Smallest</mat-label>
    
            <input type="number" min="0"
              formControlName="smallest_inches"
              matInput>
    
            <mat-hint>The thickness should be entered as inches.</mat-hint>
    
            <mat-error>Please enter the disc's smallest thickness.</mat-error>
    
          </mat-form-field>

        </div>

      </fieldset>

    </div>

  </fieldset>

  <mat-form-field>

    <mat-label>Moisture Level</mat-label>

    <input type="number"
      formControlName="moisture_percentage"
      matInput>

    <mat-hint>Should be entered as a percentage.</mat-hint>

    <mat-error>Please enter the disc's moisture level.</mat-error>

  </mat-form-field>

  <fieldset
    formGroupName="features">

    <legend>Features</legend>

    <mat-slide-toggle formControlName="arms">Arms</mat-slide-toggle>

    <mat-slide-toggle formControlName="dark_pith">Dark Pith</mat-slide-toggle>

    <mat-slide-toggle formControlName="eyes">Eyes</mat-slide-toggle>

    <mat-slide-toggle formControlName="holes">Holes</mat-slide-toggle>

    <mat-slide-toggle formControlName="horns">Horns</mat-slide-toggle>

  </fieldset>

  <mat-slide-toggle formControlName="has_pentacryl">Has Been Treated With Pentacryl</mat-slide-toggle>

</div>
