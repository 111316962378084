import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { AuthService } from '@digitaltoolbuilders/ng-auth';
import { AuthedIdentity } from '@wam/iso';

@Component({
  selector: 'app-authed-user',
  standalone: false,
  templateUrl: './authed-user.component.html',
  styleUrl: './authed-user.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthedUserComponent {

  authed: Signal<AuthedIdentity | undefined>;

  isAuthed: Signal<boolean>;

  constructor(
    private service: AuthService<AuthedIdentity>,
  ) {

    this.authed = this.service.authed

    this.isAuthed = this.service.isAuthed;

  }

  signIn() {

    this.service.signIn();

  }

  signOut() {

    this.service.signOut();

  }

}
