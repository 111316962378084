
import { HttpContentType } from '@digitaltoolbuilders/iso';

export interface FileLocation {

  content_type: HttpContentType;

  extension: string;

  name: string;

  provider: FileStorageProvider;

}

export class S3FileLocation implements FileLocation {

  bucket: string;

  content_type: HttpContentType;

  extension: string;

  key: string;

  name: string;

  provider = FileStorageProviderMap.S3;

  constructor(input: Partial<Omit<S3FileLocation, 'provider'>>) {

    if (input.bucket) this.bucket = input.bucket;
    if (input.content_type) this.content_type = input.content_type;
    if (input.extension) this.extension = input.extension;
    if (input.key) this.key = input.key;
    if (input.name) this.name = input.name;

  }

}

export const FileStorageProviderMap = {
  S3: 'aws-s3',
} as const;

export type FileStorageProvider = typeof FileStorageProviderMap[keyof typeof FileStorageProviderMap];

export const FileStorageProviders = Object.values(FileStorageProviderMap);
