import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import pino from 'pino';
const PINO_LOG_LEVEL = new InjectionToken('PINO_LOG_LEVEL', {
  factory: () => {
    return 'info';
  }
});
class LoggerService {
  level;
  logger;
  constructor(level) {
    this.level = level;
    this.logger = pino({
      level: this.level
    });
  }
  child(bindings, options) {
    return this.logger.child(bindings, options);
  }
  debug(obj, msg, ...args) {
    return this.logger.debug(obj, msg, ...args);
  }
  error(obj, msg) {
    return this.logger.error(obj, msg);
  }
  info(obj, msg, ...args) {
    return this.logger.info(obj, msg, ...args);
  }
  static ɵfac = function LoggerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LoggerService)(i0.ɵɵinject(PINO_LOG_LEVEL));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: LoggerService,
    factory: LoggerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PINO_LOG_LEVEL]
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LoggerService, PINO_LOG_LEVEL };
